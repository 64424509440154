import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Input, Spin} from 'antd';
import * as yup from 'yup';
import {useFormik} from 'formik';
import './adminLogin.scss';
import axios from 'axios';
import {BASE_URL} from '../../api/base-url';

const schema = yup.object().shape({
    login: yup.string().min(4).required(),
    password: yup.string().min(4).required(),
});

function AdminLogin() {
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const onSubmit = async (values, actions) => {
        const header = {
            headers: {'Content-Type': 'application/json'},
        };
        axios.post(
            `${BASE_URL}/api/v1/auth/profile/login`,
            {
                login: values.login,
                password: values.password,
            },
            header
        ).then((item) => {
            if (!item?.data.isError) {
                const userData = item?.data?.data;

                const fullName = {
                    name: userData?.name,
                    surname: userData?.surname,
                };

                localStorage.setItem('fullName', JSON.stringify(fullName));
                // localStorage.setItem('adminUser', JSON.stringify(userData));
                localStorage.setItem('userData', JSON.stringify(userData));
                localStorage.setItem('roleList', JSON.stringify(userData.roleList));
                localStorage.setItem('token', userData?.jwt);
                actions.resetForm();
                console.log('Keldi');
                navigate('/admin/main');
                // window.location.reload();
            } else {
                setError(item.data.message);
            }
        })
            .catch((err) => {
                setError(err.message);
            });
    };

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
    } = useFormik({
        initialValues: {
            login: '',
            password: '',
        },
        validationSchema: schema,
        onSubmit,
    });

    return (
        <div className='admin-login-wrap'>
            <div className='admin-login-form d-flex gap-4 flex-column'>
                <p className='login-title mb-0'>Admin Login / Kirish</p>
                <form onSubmit={handleSubmit}>
                    <div className='mb-3 text-start'>
                        <label htmlFor='login' className='form-label'>
                            Username
                        </label>
                        <Input
                            id='login'
                            type='text'
                            value={values.login}
                            onChange={handleChange}
                            style={{
                                padding: 13,
                                fontSize: 18,
                                borderColor: errors.login && touched.login ? 'red' : '',
                            }}
                            onBlur={handleBlur}
                        />
                        {errors.login && touched.login && (
                            <span style={{color: 'red', fontSize: 13}}>{errors.login}</span>
                        )}
                    </div>
                    <div className='mb-3 text-start'>
                        <label htmlFor='password' className='form-label'>
                            Password
                        </label>
                        <Input
                            id='password'
                            type='password'
                            value={values.password}
                            onChange={handleChange}
                            style={{
                                padding: 13,
                                fontSize: 18,
                                borderColor: errors.password && touched.password ? 'red' : '',
                            }}
                            onBlur={handleBlur}
                        />
                        {errors.password && touched.password && (
                            <span style={{color: 'red', fontSize: 13}}>
                {errors.password}
              </span>
                        )}
                    </div>
                    <button
                        disabled={isSubmitting}
                        type='submit'
                        className='button login-button'
                    >
                        {isSubmitting ? <Spin/> : 'Kirish'}
                    </button>
                    <div className='text-center'>
                        <p className='text-danger mt-3'>{error}</p>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AdminLogin;
