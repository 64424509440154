import React from 'react';
import {Footer, Header} from '../../components';
import './faq.scss';
import {Helmet} from "react-helmet-async";
export const articleList = [
    {
        id: "videohub-platformasi",
        title: "Videohub.uz qanday platforma?",
        img: "https://api.videohub.uz/api/v1/attach/open-all/6d098c03-bb80-45c7-b22e-c9a4e2e8a2ec",
        description: "Bugungi kunda onlayn ta’lim sohasining tez rivojlanishi natijasida turli kurs va darsliklar platformalari paydo bo‘ldi. Videohub.uz – shunday platformalardan biri bo‘lib, foydalanuvchilarga turli mavzulardagi videodarslarni taqdim etadi. Ushbu maqolada platformaning imkoniyatlari, afzalliklari va foydalanuvchilarga taqdim etayotgan xizmatlari haqida batafsil ma’lumot beramiz.",
        content: "<p>&nbsp;</p>\n" +
            "<h2>Videohub.uz &ndash; Online ta&rsquo;lim olamiga yo&lsquo;llanma</h2>\n" +
            "<p>Bugungi kunda onlayn ta&rsquo;lim sohasining tez rivojlanishi natijasida turli kurs va darsliklar platformalari paydo bo&lsquo;ldi. <strong>Videohub.uz</strong> &ndash; shunday platformalardan biri bo&lsquo;lib, foydalanuvchilarga turli mavzulardagi videodarslarni taqdim etadi. Ushbu maqolada platformaning imkoniyatlari, afzalliklari va foydalanuvchilarga taqdim etayotgan xizmatlari haqida batafsil ma&rsquo;lumot beramiz.</p>\n" +
            "<h2>&nbsp;</h2>\n" +
            "<h2>Videohub.uz platformasining xususiyatlari</h2>\n" +
            "<p>Videohub.uz &ndash; bu onlayn ta&rsquo;lim platformasi bo&lsquo;lib, unda foydalanuvchilar istalgan vaqtda va istalgan joyda bilim olish imkoniyatiga ega. Platforma quyidagi asosiy xususiyatlarga ega:</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>1.&nbsp;<strong>Keng qamrovli kurslar bazasi</strong></h3>\n" +
            "<p>Videohub.uz turli sohalarni qamrab olgan videodarslarni taqdim etadi. Ular orasida:</p>\n" +
            "<ul>\n" +
            "  <li>Dasturlash va IT texnologiyalari,</li>\n" +
            "  <li>Dizayn va grafika,</li>\n" +
            "  <li>Biznes va marketing,</li>\n" +
            "  <li>Shaxsiy rivojlanish,</li>\n" +
            "  <li>Fan va til o&lsquo;rganish kabi kurslar mavjud.</li>\n" +
            "</ul>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>2.&nbsp;<strong>Pullik va bepul darslar</strong></h3>\n" +
            "<p>Platformada ham pullik, ham bepul darslar mavjud. Foydalanuvchilar bepul darslar orqali platformaning imkoniyatlari bilan tanishib, keyinchalik pullik kurslarni xarid qilishlari mumkin.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>3.&nbsp;<strong>Ustozlar va mutaxassislar bilan muloqot</strong></h3>\n" +
            "<p>Videohub.uz faqatgina videodarslar bilan cheklanib qolmay, balki kurs mualliflari bilan bevosita muloqot qilish imkoniyatini ham taqdim etadi. Foydalanuvchilar o&lsquo;zlarini qiziqtirgan savollarni berishlari va muammolarini hal qilishlari mumkin.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>4.&nbsp;<strong>Moslashuvchan o&lsquo;quv jarayoni</strong></h3>\n" +
            "<p>Platforma har qanday qurilmada (kompyuter, planshet yoki smartfon) ishlaydi, shuning uchun o&lsquo;quvchilar istalgan joyda va istalgan vaqtda darslarni o&lsquo;rganishlari mumkin.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>5.&nbsp;<strong>Sertifikat olish imkoniyati</strong></h3>\n" +
            "<p>Ba&rsquo;zi kurslarni muvaffaqiyatli tamomlagan foydalanuvchilar sertifikatga ega bo&lsquo;ladilar. Bu sertifikatlar kasbiy rivojlanish yoki rezyumeda qo&lsquo;shimcha ma&rsquo;lumot sifatida ishlatilishi mumkin.</p>\n" +
            "<h2>&nbsp;</h2>\n" +
            "<h2>Videohub.uz kimlar uchun foydali?</h2>\n" +
            "<p>Videohub.uz quyidagi foydalanuvchilar uchun juda foydali bo&lsquo;lishi mumkin:</p>\n" +
            "<ul>\n" +
            "  <li><strong>Talabalar</strong> &ndash; Yangi bilimlar olish va qo&lsquo;shimcha kurslar orqali o&lsquo;z malakalarini oshirish.</li>\n" +
            "  <li><strong>Mutaxassislar</strong> &ndash; Kasbiy rivojlanish va yangi sohalarni o&lsquo;rganish.</li>\n" +
            "  <li><strong>Tadbirkorlar</strong> &ndash; Marketing, biznes va menejment bo&lsquo;yicha bilim olish.</li>\n" +
            "  <li><strong>Yangi sohani o&lsquo;rganmoqchi bo&lsquo;lganlar</strong> &ndash; Dasturlash, dizayn yoki boshqa kasblarni o&lsquo;rganish uchun.</li>\n" +
            "</ul>\n" +
            "<h2>&nbsp;</h2>\n" +
            "<h2>Xulosa</h2>\n" +
            "<p><strong>Videohub.uz</strong> &ndash; bu zamonaviy onlayn ta&rsquo;lim platformasi bo&lsquo;lib, foydalanuvchilarga sifatli va samarali bilim olish imkoniyatini taqdim etadi. Kurslarning xilma-xilligi, qulay foydalanish imkoniyatlari va ustozlar bilan bevosita muloqot qilish kabi xususiyatlari uni o&lsquo;qitishning muhim manbalaridan biriga aylantiradi. Agar siz yangi bilim olish yoki kasbiy mahoratingizni oshirishni istasangiz, <strong>Videohub.uz</strong> siz uchun ajoyib tanlov bo&lsquo;lishi mumkin!</p> <br> <br>"
    },
    {
        id: "online-talim",
        title: "Online ta’limning afzalliklari va kamchiliklari",
        img: "https://api.videohub.uz/api/v1/attach/open-all/6f874b91-74cc-4a5f-a44f-35f4e7f099dc",
        description: "Texnologiyaning rivojlanishi natijasida online ta’lim dunyo bo‘ylab ommalashib bormoqda. An’anaviy ta’lim shakllari bilan bir qatorda, internet orqali ta’lim olish ko‘plab qulayliklar yaratmoqda. Biroq, online ta’lim ham o‘ziga xos kamchiliklarga ega. Ushbu maqolada online ta’limning afzalliklari va kamchiliklari haqida batafsil ma’lumot beramiz.",
        content: "<h2>&nbsp;</h2>\n" +
            "<h2>Kirish</h2>\n" +
            "<p>Texnologiyaning rivojlanishi natijasida online ta&rsquo;lim dunyo bo&lsquo;ylab ommalashib bormoqda. An&rsquo;anaviy ta&rsquo;lim shakllari bilan bir qatorda, internet orqali ta&rsquo;lim olish ko&lsquo;plab qulayliklar yaratmoqda. Biroq, online ta&rsquo;lim ham o&lsquo;ziga xos kamchiliklarga ega. Ushbu maqolada online ta&rsquo;limning afzalliklari va kamchiliklari haqida batafsil ma&rsquo;lumot beramiz.</p>\n" +
            "<h2>&nbsp;</h2>\n" +
            "<h2>Online ta&rsquo;limning afzalliklari</h2>\n" +
            "<h3>1. <strong>Qulaylik va moslashuvchanlik</strong></h3>\n" +
            "<p>Online ta&rsquo;lim istalgan joyda va istalgan vaqtda o&lsquo;qish imkoniyatini taqdim etadi. Talabalar o&lsquo;z jadvaliga mos ravishda darslarni o&lsquo;rganishlari mumkin.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>2.&nbsp;<strong>Xarajatlarni tejash</strong></h3>\n" +
            "<p>An&rsquo;anaviy ta&rsquo;lim bilan solishtirganda, online kurslar odatda arzonroq bo&lsquo;ladi. Yo&lsquo;l xarajatlari, o&lsquo;quv qurollari va yashash joyi uchun sarflanadigan mablag&lsquo;ni tejash mumkin.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>3.&nbsp;<strong>Ko&lsquo;plab resurslarga kirish imkoniyati</strong></h3>\n" +
            "<p>Internet orqali o&lsquo;quvchilar video darslar, elektron kitoblar, maqolalar va boshqa turli xil ta&rsquo;lim resurslaridan foydalanish imkoniyatiga ega.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>4.&nbsp;<strong>Tezkor yangilanish va zamonaviy bilimlar</strong></h3>\n" +
            "<p>Online kurslar an&rsquo;anaviy darsliklarga qaraganda tez-tez yangilanadi. Bu esa o&lsquo;quvchilarga eng so&lsquo;nggi bilimlarni egallash imkoniyatini yaratadi.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>5.&nbsp;<strong>Shaxsiy rivojlanish</strong></h3>\n" +
            "<p>Mustaqil ta&rsquo;lim olish jarayonida talabalar o&lsquo;z vaqtlarini to&lsquo;g&lsquo;ri taqsimlash, mustaqil fikrlash va o&lsquo;z ustida ishlash kabi muhim ko&lsquo;nikmalarga ega bo&lsquo;ladilar.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>6.&nbsp;<strong>Videohub.uz platformasining afzalliklari</strong></h3>\n" +
            "<p>Online ta&rsquo;lim olish uchun <strong>Videohub.uz</strong> kabi platformalar katta ahamiyatga ega. Ushbu platformaning asosiy afzalliklari quyidagilardan iborat:</p>\n" +
            "<ul>\n" +
            "  <li><strong>Keng qamrovli kurslar bazasi</strong> &ndash; dasturlash, dizayn, biznes, til o&lsquo;rganish va boshqa ko&lsquo;plab yo&lsquo;nalishlarda kurslar mavjud.</li>\n" +
            "  <li><strong>Bepul va pullik darslar</strong> &ndash; foydalanuvchilar o&lsquo;z ehtiyojlariga qarab turli kurslarni tanlashlari mumkin.</li>\n" +
            "  <li><strong>Sertifikat olish imkoniyati</strong> &ndash; ba&rsquo;zi kurslarni tugatganlar uchun sertifikat beriladi, bu esa rezyumeni boyitish uchun foydali bo&lsquo;lishi mumkin.</li>\n" +
            "  <li><strong>Moslashuvchan ta&rsquo;lim</strong> &ndash; kurslarni istalgan qurilmada (kompyuter, telefon, planshet) o&lsquo;rganish imkoniyati mavjud.</li>\n" +
            "</ul>\n" +
            "<h2>&nbsp;</h2>\n" +
            "<h2>Online ta&rsquo;limning kamchiliklari</h2>\n" +
            "<h3>1. <strong>Motivatsiya va o&lsquo;z-o&lsquo;zini nazorat qilish muammosi</strong></h3>\n" +
            "<p>Ba&rsquo;zi o&lsquo;quvchilar online o&lsquo;qish jarayonida yetarlicha motivatsiya topa olmaydilar. O&lsquo;qituvchining bevosita nazorati bo&lsquo;lmagani sababli, darslarni kechiktirish yoki tashlab qo&lsquo;yish ehtimoli ortadi.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>2.&nbsp;<strong>Amaliy mashg&lsquo;ulotlarning yetishmovchiligi</strong></h3>\n" +
            "<p>Ba&rsquo;zi sohalarda, ayniqsa, amaliy mashg&lsquo;ulotlar talab qilinadigan yo&lsquo;nalishlarda online ta&rsquo;lim yetarlicha samarali bo&lsquo;lmasligi mumkin. Masalan, tibbiyot yoki muhandislik kabi sohalarda amaliy tajriba juda muhim hisoblanadi.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>3.&nbsp;<strong>Internet va texnik muammolar</strong></h3>\n" +
            "<p>Online ta&rsquo;lim to&lsquo;liq internetga bog&lsquo;liq bo&lsquo;lgani sababli, internet sifati past bo&lsquo;lgan hududlarda ta&rsquo;lim olish qiyin bo&lsquo;lishi mumkin. Bundan tashqari, ba&rsquo;zan texnik muammolar dars jarayoniga halal berishi mumkin.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>4.&nbsp;<strong>Cheklangan ijtimoiy muloqot</strong></h3>\n" +
            "<p>An&rsquo;anaviy ta&rsquo;limda talabalar o&lsquo;zaro muloqot qiladilar, muhokamalar olib boradilar va jamoaviy loyihalarda ishtirok etadilar. Online ta&rsquo;lim esa ijtimoiy muloqotni cheklab qo&lsquo;yishi mumkin.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>5.&nbsp;<strong>Ish beruvchilar tomonidan tan olinishi muammosi</strong></h3>\n" +
            "<p>Ba&rsquo;zi ish beruvchilar online kurslarni an&rsquo;anaviy universitet yoki kollej diplomi kabi ishonchli deb bilmasligi mumkin. Shuning uchun online kursni tanlashdan oldin uning obro&lsquo;si va sertifikati tan olinishi muhimligini tekshirib ko&lsquo;rish kerak.</p>\n" +
            "<h2>&nbsp;</h2>\n" +
            "<h2>Xulosa</h2>\n" +
            "<p>Online ta&rsquo;lim zamonaviy ta&rsquo;lim shakli bo&lsquo;lib, uning afzalliklari va kamchiliklari mavjud. Moslashuvchanlik, arzonlik va keng resurslar kabi afzalliklari bor bo&lsquo;lsa-da, amaliy mashg&lsquo;ulotlarning cheklanganligi, motivatsiya muammolari va texnik qiyinchiliklar ham yuzaga kelishi mumkin. Shuning uchun, online ta&rsquo;limni tanlashdan oldin uning o&lsquo;ziga xos jihatlarini chuqur o&lsquo;rganish va shaxsiy ehtiyojlarga mos kelishini aniqlash lozim. <strong>Videohub.uz</strong> kabi platformalar esa online ta&rsquo;limning qulay va sifatli bo&lsquo;lishiga yordam beradi.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<p>&nbsp;</p>" },
    {
        id: "dasturlash-olami",
        title: "Dasturlash olami",
        img: "https://api.videohub.uz/api/v1/attach/open-all/cbd675c1-1f04-4459-aace-2ccd88064768",
        description: "Dasturlash bugungi kunda dunyodagi eng tez rivojlanayotgan sohalardan biri hisoblanadi. Texnologiyaning rivojlanishi bilan dasturlash turli sohalarga chuqur kirib bormoqda va hayotimizning ajralmas qismiga aylanmoqda. Ushbu maqolada dasturlash olami, uning asosiy yo‘nalishlari, dasturchi bo‘lish yo‘llari va sohaning istiqbollari haqida so‘z yuritamiz.",
        content: "<h2>&nbsp;</h2>\n" +
            "<h2>Kirish</h2>\n" +
            "<p>Dasturlash bugungi kunda dunyodagi eng tez rivojlanayotgan sohalardan biri hisoblanadi. Texnologiyaning rivojlanishi bilan dasturlash turli sohalarga chuqur kirib bormoqda va hayotimizning ajralmas qismiga aylanmoqda. Ushbu maqolada dasturlash olami, uning asosiy yo&lsquo;nalishlari, dasturchi bo&lsquo;lish yo&lsquo;llari va sohaning istiqbollari haqida so&lsquo;z yuritamiz.</p>\n" +
            "<h2>&nbsp;</h2>\n" +
            "<h2>Dasturlash nima?</h2>\n" +
            "<p>Dasturlash &ndash; bu kompyuter yoki boshqa qurilmalarga ma&rsquo;lum bir vazifani bajarish uchun buyruqlar yozish jarayonidir. Bu jarayon dasturlash tillari yordamida amalga oshiriladi. Dasturchilar turli dasturiy ta&rsquo;minot, mobil ilovalar, veb-saytlar va sun&rsquo;iy intellekt tizimlarini ishlab chiqadilar.</p>\n" +
            "<h2>&nbsp;</h2>\n" +
            "<h2>Dasturlash yo&lsquo;nalishlari</h2>\n" +
            "<p>Dasturlash turli sohalarni qamrab oladi va u quyidagi asosiy yo&lsquo;nalishlarga bo&lsquo;linadi:</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>1.&nbsp;<strong>Veb dasturlash</strong></h3>\n" +
            "<p>Veb dasturchilar saytlar va veb-ilovalar yaratadilar. Bu yo&lsquo;nalish <strong>frontend</strong> (foydalanuvchi interfeysi) va <strong>backend</strong> (server qismi) dasturlashiga bo&lsquo;linadi. Mashhur texnologiyalar:</p>\n" +
            "<ul>\n" +
            "  <li>Frontend: HTML, CSS, JavaScript, React, Vue.js</li>\n" +
            "  <li>Backend: Node.js, PHP, Python (Django, Flask), Java (Spring)</li>\n" +
            "</ul>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>2.&nbsp;<strong>Mobil dasturlash</strong></h3>\n" +
            "<p>Mobil ilovalarni yaratish uchun Android va iOS platformalari uchun dasturlash amalga oshiriladi.</p>\n" +
            "<ul>\n" +
            "  <li>Android: Java, Kotlin</li>\n" +
            "  <li>iOS: Swift</li>\n" +
            "  <li>Kross-platforma: Flutter, React Native</li>\n" +
            "</ul>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>3.&nbsp;<strong>Ma&rsquo;lumotlar tahlili va sun&rsquo;iy intellekt</strong></h3>\n" +
            "<p>Bugungi kunda katta ma&rsquo;lumotlar (Big Data) va sun&rsquo;iy intellekt sohalari tez rivojlanmoqda.</p>\n" +
            "<ul>\n" +
            "  <li>Ma&rsquo;lumotlar tahlili: Python (Pandas, NumPy), R</li>\n" +
            "  <li>Sun&rsquo;iy intellekt: TensorFlow, PyTorch</li>\n" +
            "</ul>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>4.&nbsp;<strong>O&lsquo;yinga dasturlash</strong></h3>\n" +
            "<p>O&lsquo;yin yaratish uchun maxsus dvijoklar va dasturlash tillari ishlatiladi:</p>\n" +
            "<ul>\n" +
            "  <li>Unity (C#)</li>\n" +
            "  <li>Unreal Engine (C++)</li>\n" +
            "</ul>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>5.&nbsp;<strong>Kiberxavfsizlik</strong></h3>\n" +
            "<p>Kiberxavfsizlik dasturchilari tizimlarni himoya qilish va xakerlik hujumlariga qarshi kurashishda ishtirok etadilar.</p>\n" +
            "<ul>\n" +
            "  <li>Mashhur vositalar: Kali Linux, Metasploit, Wireshark</li>\n" +
            "</ul>\n" +
            "<h2>&nbsp;</h2>\n" +
            "<h2>Dasturchi bo&lsquo;lish yo&lsquo;llari</h2>\n" +
            "<p>Dasturchi bo&lsquo;lish uchun quyidagi bosqichlarni bosib o&lsquo;tish tavsiya etiladi:</p>\n" +
            "<ol>\n" +
            "  <li><strong>Dasturlash asoslarini o&lsquo;rganish</strong> &ndash; Birinchi bosqichda Python yoki JavaScript kabi sodda dasturlash tillaridan boshlash tavsiya etiladi.</li>\n" +
            "  <li><strong>Amaliy mashg&lsquo;ulotlar bajarish</strong> &ndash; O&lsquo;rganilgan bilimlarni mustahkamlash uchun loyihalar ustida ishlash lozim.</li>\n" +
            "  <li><strong>Portfolio yaratish</strong> &ndash; O&lsquo;z ishlanmalaringizni GitHub yoki shaxsiy veb-saytingizga joylash.</li>\n" +
            "  <li><strong>Sertifikatlar olish</strong> &ndash; Udemy, Coursera, Codecademy kabi platformalarda kurslarni tamomlash.</li>\n" +
            "  <li><strong>Ish va amaliyot topish</strong> &ndash; Junior dasturchi sifatida ishlash va tajriba orttirish.</li>\n" +
            "</ol>\n" +
            "<h2>&nbsp;</h2>\n" +
            "<h2>Dasturlashning kelajagi</h2>\n" +
            "<p>Dasturlash sohasi doimiy rivojlanib boradi va quyidagi yo&lsquo;nalishlarda yanada o&lsquo;sishi kutilmoqda:</p>\n" +
            "<ul>\n" +
            "  <li>Sun&rsquo;iy intellekt va avtomatlashtirish</li>\n" +
            "  <li>Bulut texnologiyalari</li>\n" +
            "  <li>Kiberxavfsizlik</li>\n" +
            "  <li>Kvant hisoblash</li>\n" +
            "</ul>\n" +
            "<h2>&nbsp;</h2>\n" +
            "<h2>Xulosa</h2>\n" +
            "<p>Dasturlash olami juda keng va qiziqarli. Har bir inson o&lsquo;z qiziqishlariga qarab ushbu sohaga kirib kelishi va o&lsquo;z kasbini yaratishi mumkin. Dasturlashni o&lsquo;rganish orqali zamonaviy texnologiyalar bilan ishlash, ijodkorlik va muammolarni hal qilish qobiliyatlarini rivojlantirish mumkin. Agar siz ham dasturlashni o&lsquo;rganmoqchi bo&lsquo;lsangiz, hoziroq boshlash uchun eng yaxshi vaqt!</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<p>&nbsp;</p>" },
    {
        id: "backend-dasturlash",
        title: "Backend dasturlash",
        img: "https://api.videohub.uz/api/v1/attach/open-all/2e05a42d-b542-40b3-bb99-2113f4d4ee38",
        description: "Dasturlash olami frontend va backend qismlariga bo‘linadi. Backend dasturlash veb-sayt yoki ilovaning ichki qismi bilan shug‘ullanadi va serverda ishlov berish, ma’lumotlar bazasi bilan ishlash va autentifikatsiya kabi muhim jarayonlarni boshqaradi. Ushbu maqolada backend dasturlashning asosiy tushunchalari, texnologiyalari va uning dasturchilar uchun ahamiyati haqida so‘z yuritamiz.",
        content: "<h2>&nbsp;</h2>\n" +
            "<h2>Kirish</h2>\n" +
            "<p>Dasturlash olami frontend va backend qismlariga bo&lsquo;linadi. Backend dasturlash veb-sayt yoki ilovaning ichki qismi bilan shug&lsquo;ullanadi va serverda ishlov berish, ma&rsquo;lumotlar bazasi bilan ishlash va autentifikatsiya kabi muhim jarayonlarni boshqaradi. Ushbu maqolada backend dasturlashning asosiy tushunchalari, texnologiyalari va uning dasturchilar uchun ahamiyati haqida so&lsquo;z yuritamiz.</p>\n" +
            "<h2>&nbsp;</h2>\n" +
            "<h2>Backend dasturlash nima?</h2>\n" +
            "<p>Backend dasturlash &ndash; bu foydalanuvchiga ko&lsquo;rinmaydigan, lekin veb-sayt yoki ilovaning ishlashini ta&rsquo;minlovchi dasturiy ta&rsquo;minot qismi. U server, ma&rsquo;lumotlar bazasi va API (Application Programming Interface) kabi komponentlardan iborat bo&lsquo;ladi.</p>\n" +
            "<h2>&nbsp;</h2>\n" +
            "<h2>Backend dasturlashning asosiy komponentlari</h2>\n" +
            "<h3>1. <strong>Server</strong></h3>\n" +
            "<p>Server backend dasturlarni ishga tushiradigan va mijozlarning so&lsquo;rovlarini qayta ishlaydigan kompyuter yoki tarmoq xizmatidir. Mashhur server texnologiyalari:</p>\n" +
            "<ul>\n" +
            "  <li>Apache</li>\n" +
            "  <li>Nginx</li>\n" +
            "  <li>Microsoft IIS</li>\n" +
            "</ul>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>2.&nbsp;<strong>Dasturlash tillari</strong></h3>\n" +
            "<p>Backend dasturchilar turli dasturlash tillaridan foydalanadilar:</p>\n" +
            "<ul>\n" +
            "  <li><strong>Java</strong> (Spring Boot, Jakarta EE)</li>\n" +
            "  <li><strong>Python</strong> (Django, Flask, FastAPI)</li>\n" +
            "  <li><strong>JavaScript</strong> (Node.js, Express.js)</li>\n" +
            "  <li><strong>PHP</strong> (Laravel, Symfony)</li>\n" +
            "  <li><strong>Ruby</strong> (Ruby on Rails)</li>\n" +
            "  <li><strong>C#</strong> (ASP.NET)</li>\n" +
            "</ul>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>3.&nbsp;<strong>Ma&rsquo;lumotlar bazasi</strong></h3>\n" +
            "<p>Backend dasturlar ma&rsquo;lumotlarni saqlash va boshqarish uchun ma&rsquo;lumotlar bazalaridan foydalanadi:</p>\n" +
            "<ul>\n" +
            "  <li><strong>Relyatsion ma&rsquo;lumotlar bazalari (SQL)</strong>: MySQL, PostgreSQL, Microsoft SQL Server</li>\n" +
            "  <li><strong>NoSQL ma&rsquo;lumotlar bazalari</strong>: MongoDB, Redis, Firebase</li>\n" +
            "</ul>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>4.&nbsp;<strong>API (Dasturiy interfeyslar)</strong></h3>\n" +
            "<p>API foydalanuvchi interfeysi va server orasida muloqotni ta&rsquo;minlaydi. Mashhur API texnologiyalari:</p>\n" +
            "<ul>\n" +
            "  <li><strong>RESTful API</strong></li>\n" +
            "  <li><strong>GraphQL</strong></li>\n" +
            "  <li><strong>gRPC</strong></li>\n" +
            "</ul>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>5.&nbsp;<strong>Autentifikatsiya va xavfsizlik</strong></h3>\n" +
            "<p>Backend dasturlar foydalanuvchi ma&rsquo;lumotlarini himoya qilish uchun autentifikatsiya va xavfsizlik choralarini qo&lsquo;llaydi:</p>\n" +
            "<ul>\n" +
            "  <li>JWT (JSON Web Token)</li>\n" +
            "  <li>OAuth 2.0</li>\n" +
            "  <li>Hashing va shifrlash texnologiyalari</li>\n" +
            "</ul>\n" +
            "<h2>&nbsp;</h2>\n" +
            "<h2>Backend dasturchi bo&lsquo;lish yo&lsquo;llari</h2>\n" +
            "<ol>\n" +
            "  <li><strong>Dasturlash tilini tanlash</strong> &ndash; Java, Python yoki Node.js kabi backend tillaridan birini chuqur o&lsquo;rganish.</li>\n" +
            "  <li><strong>Ma&rsquo;lumotlar bazasi bilan ishlash</strong> &ndash; SQL va NoSQL ma&rsquo;lumotlar bazalarini o&lsquo;rganish.</li>\n" +
            "  <li><strong>API yaratish</strong> &ndash; REST va GraphQL API-larini ishlab chiqish.</li>\n" +
            "  <li><strong>Serverlar va hostingni tushunish</strong> &ndash; Server konfiguratsiyasi va bulut texnologiyalarini o&lsquo;rganish.</li>\n" +
            "  <li><strong>Amaliy loyihalar bajarish</strong> &ndash; Portfolioga kirish uchun loyihalar ustida ishlash.</li>\n" +
            "  <li><strong>Versiya nazorati tizimlarini bilish</strong> &ndash; Git va GitHub kabi vositalardan foydalanish.</li>\n" +
            "</ol>\n" +
            "<h2>&nbsp;</h2>\n" +
            "<h2>Backend dasturlashning kelajagi</h2>\n" +
            "<p>Backend dasturlash doimiy ravishda rivojlanmoqda va quyidagi yo&lsquo;nalishlarda o&lsquo;sishda davom etmoqda:</p>\n" +
            "<ul>\n" +
            "  <li><strong>Bulut texnologiyalari</strong> &ndash; AWS, Google Cloud va Microsoft Azure</li>\n" +
            "  <li><strong>Mikroxizmatlar arxitekturasi</strong> &ndash; Kubernetes, Docker</li>\n" +
            "  <li><strong>Sun&rsquo;iy intellekt va avtomatlashtirish</strong></li>\n" +
            "</ul>\n" +
            "<h2>&nbsp;</h2>\n" +
            "<h2>Xulosa</h2>\n" +
            "<p>Backend dasturlash veb-sayt va ilovalarning asosiy dvigatelidir. Dasturchilar uchun bu soha keng imkoniyatlar yaratib, texnologik taraqqiyotga o&lsquo;z hissasini qo&lsquo;shish imkonini beradi. Backend dasturlashni o&lsquo;rganish orqali katta miqyosli loyihalar yaratish va texnologik muammolarni hal qilish mumkin.</p>\n" +
            "<p>&nbsp;</p>" },
    {
        id: "java-dasturlash-tili-haqida",
        title: "Java dasturlash tili haqida",
        img: "https://api.videohub.uz/api/v1/attach/open-all/08ef0549-d3e2-4d0a-8281-c6a60a04071d",
        description: "<h1 style=\"text-align: center;\"><strong>Java</strong></h1>\n" +
            "<p>&nbsp;</p>\n" +
            "<h2><strong>Kirish</strong></h2>\n" +
            "<p>Dasturlashni o&lsquo;rganishni istagan har bir inson o&lsquo;ziga <strong>\"Qaysi dasturlash tilini tanlash kerak?\"</strong> degan savolni beradi. Agar siz universal, kuchli va keng qo&lsquo;llaniladigan dasturlash tilini izlayotgan bo&lsquo;lsangiz, <strong>Java</strong> eng yaxshi tanlovlardan biri hisoblanadi. <strong>Java</strong> nafaqat <strong>veb</strong>, <strong>mobil</strong>, va <strong>desktop dasturlarini</strong> yaratishda, balki <strong>sun&rsquo;iy intellekt</strong>, <strong>big data</strong>, va <strong>cloud computing</strong> sohalarida ham keng qo&lsquo;llaniladi.</p>\n" +
            "<p>Agar siz <strong>Java dasturlash tilini boshidan professional darajaga qadar o&lsquo;rganmoqchi bo&lsquo;lsangiz</strong>, <strong>Videohub.uz</strong> platformasida mavjud bo&lsquo;lgan <strong>Java Foundation, Java OOP va Java Spring</strong> kurslari sizga yordam beradi. Ushbu kurslar sizni <strong>Java dasturchisi</strong> bo&lsquo;lish yo&lsquo;lida mustahkam bilim va tajribaga ega bo&lsquo;lishingizni ta&rsquo;minlaydi.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Nima uchun aynan Java?</strong></h2>\n" +
            "<h3><strong>1. Kross-platforma (Cross-Platform) qo&lsquo;llab-quvvatlashi</strong></h3>\n" +
            "<p>Java <strong>\"Write Once, Run Anywhere\"</strong> tamoyiliga asoslanadi. Ya&rsquo;ni, bir marta yozilgan dastur <strong>Windows, MacOS, Linux</strong> va hatto <strong>mobil</strong> qurilmalarda ham ishlay oladi.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3><strong>2. Kuchli obyektga yo&lsquo;naltirilgan dasturlash (OOP) modeli</strong></h3>\n" +
            "<p>Java <strong>obyektga yo&lsquo;naltirilgan dasturlash</strong> (OOP) tamoyillariga asoslangan bo&lsquo;lib, kodning <strong>tartibli</strong>, <strong>tushunarli</strong> va <strong>qayta ishlatiladigan</strong> bo&lsquo;lishini ta&rsquo;minlaydi.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3><strong>3. Xavfsizlik va ishonchlilik</strong></h3>\n" +
            "<p>Java <strong>avtomatik xotira boshqaruvi</strong> (Garbage Collection) va <strong>mustahkam xavfsizlik mexanizmlari</strong> bilan boshqa dasturlash tillariga nisbatan ancha ishonchli hisoblanadi.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3><strong>4. Katta dasturchilar hamjamiyati va resurslar ko&lsquo;pligi</strong></h3>\n" +
            "<p>Java dunyo bo&lsquo;ylab <strong>millionlab dasturchilar</strong> tomonidan ishlatiladi va juda katta hamjamiyatga ega. Bu esa yangi o&lsquo;rganuvchilar uchun juda foydali, chunki <strong>o&lsquo;rganish uchun ko&lsquo;plab resurslar mavjud</strong>.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2>&nbsp;</h2>\n" +
            "<h2><strong>Java dasturlarini qayerdan o&lsquo;rganish mumkin?</strong></h2>\n" +
            "<p>Agar siz <strong>Java dasturlash tilini noldan professional darajaga qadar o&lsquo;rganmoqchi</strong> bo&lsquo;lsangiz, <strong>Videohub.uz</strong> saytidagi kurslar siz uchun ideal variant hisoblanadi.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3><strong>1. Videohub.uz &ndash; Java kurslari</strong></h3>\n" +
            "<p><strong>Videohub.uz</strong> platformasida quyidagi <strong>Java kurslari</strong> mavjud:</p>\n" +
            "<ul>\n" +
            "  <li><strong>Java Foundation</strong> &ndash; Java asoslarini o&lsquo;rganish uchun.</li>\n" +
            "  <li><strong>Java OOP</strong> &ndash; obyektga yo&lsquo;naltirilgan dasturlash bo&lsquo;yicha chuqur tushunchalar.</li>\n" +
            "  <li><strong>Java Spring</strong> &ndash; Java orqali web ilovalar yaratish texnologiyalarini o&lsquo;rganish.</li>\n" +
            "</ul>\n" +
            "<p>Ushbu kurslar orqali siz <strong>Java dasturchisi bo&lsquo;lish yo&lsquo;lida zarur barcha bilimlarga ega bo&lsquo;lasiz</strong>.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Java dasturchisi bo&lsquo;lish yo&lsquo;lida nimalarga e&rsquo;tibor berish kerak?</strong></h2>\n" +
            "<h3><strong>1. Java sintaksisini o&lsquo;rganing</strong></h3>\n" +
            "<p>Java sintaksisi oddiy va tushunarli. Dasturlashni <strong>\"Hello, World!\"</strong> dasturidan boshlashingiz mumkin:</p>\n" +
            "<pre><code class=\"language-java\">public class Main {\n" +
            "    public static void main(String[] args) {\n" +
            "        System.out.println(\"Hello, World!\");\n" +
            "    }\n" +
            "}\n" +
            "</code></pre>\n" +
            "<p>Bu kod ekranga <strong>\"Hello, World!\"</strong> degan yozuvni chiqaradi.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<h3><strong>2. OOP (obyektga yo&lsquo;naltirilgan dasturlash) asoslarini o&lsquo;rganing</strong></h3>\n" +
            "<p>Java <strong>obyektga yo&lsquo;naltirilgan dasturlash</strong> (OOP) tamoyillariga asoslangan bo&lsquo;lib, <strong>inkapsulyatsiya, meros olish, polimorfizm va abstraksiya</strong> kabi tushunchalarni o&lsquo;z ichiga oladi.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3><strong>3. Java kutubxonalaridan foydalanishni o&lsquo;rganing</strong></h3>\n" +
            "<p>Java <strong>Spring</strong>, <strong>Hibernate</strong>, <strong>JavaFX</strong> kabi kuchli framework va kutubxonalarga ega. Bularni o&lsquo;rganish orqali siz professional darajadagi dasturlar yozishingiz mumkin.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3><strong>4. Amaliy loyihalar ustida ishlang</strong></h3>\n" +
            "<p>O&lsquo;rgangan bilimlaringizni mustahkamlash uchun kichik <strong>amaliy loyihalar</strong> ustida ishlash juda foydali. Masalan:</p>\n" +
            "<ul>\n" +
            "  <li>Kalkulyator dasturi</li>\n" +
            "  <li>O&lsquo;yin yaratish</li>\n" +
            "  <li>Veb-ilovalar ishlab chiqish</li>\n" +
            "</ul>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3><strong>5. Dasturchilar hamjamiyatiga qo&lsquo;shiling</strong></h3>\n" +
            "<p>Dasturchi bo&lsquo;lish yo&lsquo;lida <strong>hamjamiyat bilan aloqa qilish juda muhim</strong>. <strong>Stack Overflow, GitHub, LinkedIn</strong> kabi platformalarda o&lsquo;z loyihalaringizni baham ko&lsquo;rib, tajribali dasturchilardan maslahat olishingiz mumkin.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Xulosa</strong></h2>\n" +
            "<p>Agar siz <strong>dasturlashni o&lsquo;rganishni istasangiz</strong>, Java siz uchun eng yaxshi tanlov bo&lsquo;lishi mumkin. U <strong>xavfsiz</strong>, <strong>mustahkam</strong>, <strong>kuchli</strong> va <strong>platformadan mustaqil</strong> til hisoblanadi.</p>\n" +
            "<p>Siz <strong>Java dasturlash tilini mukammal o&lsquo;rganmoqchi bo&lsquo;lsangiz</strong>:<br>✅ <strong>Videohub.uz</strong> platformasidagi <strong>Java Foundation, Java OOP, Java Spring</strong> kurslaridan foydalaning.<br>✅ <strong>Amaliy mashqlar</strong> bajaring va o&lsquo;rganish jarayonida loyihalar ishlab chiqing.<br>✅ <strong>Dasturlash.uz</strong> platformasidagi <strong>Java bo&lsquo;yicha maqolalar</strong> orqali bilimlaringizni chuqurlashtiring.</p>\n" +
            "<p>Bugun <strong>Java dasturchisi bo&lsquo;lish sari ilk qadamni tashlang</strong>! 🚀</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<p>&nbsp;</p>" },
    {
        id: "spring-framework-haqida",
        title: "Spring Framework haqida",
        img: "https://api.videohub.uz/api/v1/attach/open-all/e22123ee-5b70-4260-9f11-28a16862b610",
        description: "Spring Framework &ndash; bu Java dasturlarini yaratish uchun mo&lsquo;ljallangan kuchli va moslashuvchan ramka (framework) bo&lsquo;lib, u veb-ilovalar, mikroservislar va korxona darajasidagi dasturlarni ishlab chiqishda keng qo&lsquo;llaniladi. Spring dasturchilarga kod yozish jarayonini soddalashtirish, xavfsizlikni oshirish va ilovalarni modulli tarzda yaratish imkonini beradi.",
        content: "<h1>&nbsp;</h1>\n" +
            "<h1><strong>Spring Framework: Java dasturlash uchun kuchli vosita</strong></h1>\n" +
            "<p>&nbsp;</p>\n" +
            "<h2><strong>Kirish</strong></h2>\n" +
            "<p>Dasturchilar uchun <strong>Spring Framework</strong> &ndash; <strong>Java</strong> tilida <strong>kuchli, moslashuvchan va samarali</strong> ilovalar yaratish imkonini beruvchi eng mashhur frameworklardan biridir. U asosan <strong>veb-ilovalar</strong>, <strong>mikroxizmatlar</strong>, va <strong>korporativ dasturlar</strong> ishlab chiqishda qo&lsquo;llaniladi. <strong>Spring</strong> o&lsquo;zining <strong>modulligi, IoC (Inversion of Control), DI (Dependency Injection)</strong> va <strong>yuksak xavfsizlik mexanizmlari</strong> bilan ajralib turadi.</p>\n" +
            "<p>Agar siz <strong>Spring Framework</strong> asoslarini o&lsquo;rganmoqchi bo&lsquo;lsangiz, <strong>Videohub.uz</strong> platformasi siz uchun ajoyib imkoniyat yaratadi. <strong>Videohub.uz</strong> sayti orqali <strong>Spring Core, Spring Security, Spring Data JPA, Spring Boot, Spring Rest</strong> kabi yo&lsquo;nalishlarni bosqichma-bosqich o&lsquo;rganishingiz mumkin.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Spring Framework nima va u qanday ishlaydi?</strong></h2>\n" +
            "<p>Spring &ndash; bu <strong>Java EE (Enterprise Edition)</strong> asosida yaratilgan <strong>framework</strong> bo&lsquo;lib, dasturchilarga <strong>moslashuvchan va oson boshqariladigan dasturlar</strong> yaratishda yordam beradi.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<h3><strong>Spring Framework ning asosiy komponentlari:</strong></h3>\n" +
            "<p>✅ <strong>Spring Core</strong> &ndash; Frameworkning asosiy qismi bo&lsquo;lib, <strong>Dependency Injection (DI)</strong> va <strong>Inversion of Control (IoC)</strong> konseptlariga asoslanadi.<br>✅ <strong>Spring Security</strong> &ndash; Ilovalarda <strong>xavfsizlik</strong> ta&rsquo;minlash uchun ishlatiladi.<br>✅ <strong>Spring Data JPA</strong> &ndash; <strong>Ma&rsquo;lumotlar bazasi</strong> bilan ishlashni soddalashtiradi.<br>✅ <strong>Spring Boot</strong> &ndash; <strong>Spring Framework</strong> bilan tezkor dasturlar yaratish uchun eng yaxshi tanlov.<br>✅ <strong>Spring REST</strong> &ndash; <strong>RESTful API</strong> larni yaratish va boshqarish imkonini beradi.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Spring Framework ning afzalliklari</strong></h2>\n" +
            "<h3><strong>1. Moslashuvchan va kengaytiriladigan tuzilma</strong></h3>\n" +
            "<p>Spring <strong>har qanday turdagi dasturlar</strong> uchun mos keladi &ndash; oddiy dasturlardan tortib, <strong>katta korporativ ilovalar</strong>gacha.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3><strong>2. IoC va DI orqali kodning tartibli bo&lsquo;lishi</strong></h3>\n" +
            "<p>Spring <strong>IoC (Inversion of Control)</strong> va <strong>DI (Dependency Injection)</strong> kabi texnologiyalarni qo&lsquo;llab-quvvatlaydi, bu esa kodni <strong>modulli</strong>, <strong>testga qulay</strong> va <strong>kengaytiriladigan</strong> holatga keltiradi.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3><strong>3. Ma&rsquo;lumotlar bazasi bilan integratsiya</strong></h3>\n" +
            "<p>Spring <strong>JPA, Hibernate, JDBC</strong> kabi <strong>ma&rsquo;lumotlar bazasi texnologiyalari</strong> bilan mukammal ishlaydi.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3><strong>4. Katta dasturchilar hamjamiyati va kuchli qo&lsquo;llab-quvvatlash</strong></h3>\n" +
            "<p>Spring <strong>ochiq kodli</strong> bo&lsquo;lgani sababli, uni rivojlantirishda <strong>juda ko&lsquo;p dasturchilar</strong> ishtirok etadi va <strong>doimiy yangilanishlar</strong> olib boriladi.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3><strong>5. Microservices va Cloud Computing uchun qulaylik</strong></h3>\n" +
            "<p>Spring <strong>Mikroxizmatlar</strong> arxitekturasida katta rol o&lsquo;ynaydi va <strong>AWS, Azure, Google Cloud</strong> kabi platformalar bilan mukammal ishlaydi.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Spring Framework komponentlari</strong></h2>\n" +
            "<h3><strong>1. Spring Core</strong></h3>\n" +
            "<p>Spring&rsquo;ning asosiy qismi bo&lsquo;lib, <strong>IoC va DI</strong> tamoyillari orqali <strong>bog&lsquo;liqliklarni avtomatlashtiradi</strong>.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3><strong>2. Spring Security</strong></h3>\n" +
            "<p>Ilovalarning <strong>xavfsizligini ta&rsquo;minlash</strong> uchun ishlatiladi. U <strong>authentication, authorization, JWT token</strong> va <strong>OAuth2</strong> kabi texnologiyalarni qo&lsquo;llab-quvvatlaydi.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3><strong>3. Spring Data JPA</strong></h3>\n" +
            "<p><strong>Ma&rsquo;lumotlar bazasi bilan ishlashni soddalashtiradi</strong>. Dasturchilar oddiy <strong>repository</strong> interfeyslarini yaratish orqali ma&rsquo;lumotlar bazasi bilan bog&lsquo;lanishlari mumkin.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3><strong>4. Spring Boot</strong></h3>\n" +
            "<p>Spring&rsquo;ning <strong>eng mashhur qismi</strong> bo&lsquo;lib, tezkor dasturlar yaratish imkonini beradi. <strong>Spring Boot</strong> orqali <strong>konfiguratsiya</strong> ishlari minimal darajaga tushiriladi.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3><strong>5. Spring REST</strong></h3>\n" +
            "<p><strong>RESTful API</strong> lar yaratish uchun <strong>Spring MVC</strong> asosida ishlab chiqilgan modul. Dasturchilar <strong>JSON yoki XML</strong> formatida ma&rsquo;lumot almashishlari mumkin.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Spring Framework ni qayerdan o&lsquo;rganish mumkin?</strong></h2>\n" +
            "<p>Agar siz <strong>Spring Framework</strong> ni noldan o&lsquo;rganmoqchi bo&lsquo;lsangiz, <strong>Videohub.uz</strong> platformasidagi darslar siz uchun eng yaxshi tanlov. <strong>Videohub.uz</strong> sayti orqali siz:</p>\n" +
            "<ul>\n" +
            "  <li><strong>Spring Core</strong> &ndash; Spring asoslari va IoC, DI tushunchalarini o&lsquo;rganishingiz mumkin.</li>\n" +
            "  <li><strong>Spring Security</strong> &ndash; Ilovalarda autentifikatsiya va avtorizatsiya ishlashini o&lsquo;rganasiz.</li>\n" +
            "  <li><strong>Spring Data JPA</strong> &ndash; Ma&rsquo;lumotlar bazasi bilan samarali ishlash.</li>\n" +
            "  <li><strong>Spring Boot</strong> &ndash; Spring ilovalarini tez va qulay ishlab chiqish.</li>\n" +
            "  <li><strong>Spring REST</strong> &ndash; RESTful API lar yaratish.</li>\n" +
            "</ul>\n" +
            "<p>Bundan tashqari, <strong>Dasturlash.uz</strong> sayti orqali <strong>Spring Framework</strong> bo&lsquo;yicha o&lsquo;zbek tilida maqolalar o&lsquo;qib, tushunchalaringizni yanada mustahkamlashingiz mumkin.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Xulosa</strong></h2>\n" +
            "<p><strong>Spring Framework</strong> &ndash; bu <strong>Java dasturchilari</strong> uchun <strong>eng kuchli</strong> va <strong>ko&lsquo;p imkoniyatlarga ega bo&lsquo;lgan</strong> texnologiya. Agar siz <strong>Java bilan web yoki korporativ dasturlar yaratmoqchi bo&lsquo;lsangiz</strong>, albatta <strong>Spring Framework</strong> ni o&lsquo;rganishingiz kerak.</p>\n" +
            "<p>✅ <strong>Videohub.uz</strong> platformasida <strong>Spring Core, Spring Security, Spring Data JPA, Spring Boot, Spring REST</strong> kabi kurslarni o&lsquo;rganish orqali <strong>Spring Framework</strong> ni mukammal egallashingiz mumkin.<br>✅ <strong>Dasturlash.uz</strong> saytida <strong>Spring bo&lsquo;yicha o&lsquo;zbek tilidagi maqolalar</strong> mavjud.</p>\n" +
            "<p>Agar siz <strong>professional Java backend dasturchisi</strong> bo&lsquo;lishni istasangiz, <strong>hoziroq o&lsquo;rganishni boshlang</strong>! 🚀</p>"    },
    {
        id: "hibernate",
        title: "Hibernate",
        img: "https://api.videohub.uz/api/v1/attach/open-all/ea38c562-66b0-45c4-9f97-c9e7be0a5ede",
        description: "Hibernate - bu Java dasturlarida malumotlar bazasi bilan ishlashni osonlashtiruvchi kuchli ORM (Object-Relational Mapping) texnologiyasidir. Hibernate SQL so'rovlarini avtomatlashtirib, dasturchilarga obyektlarga asoslangan (OOP) yondashuvda ma'lumotlarni boshqarish imkonini beradi. Bu texnologiya katta hajmdagi ma'lumotlar bilan ishlash jarayonini sezilarli darajada soddalashtiradi va samaradorlikni oshiradi.",
        content: "<h1>&nbsp;</h1>\n" +
            "<h1><strong>Hibernate &ndash; Java uchun ma&rsquo;lumotlar bazasi boshqaruv tizimi</strong></h1>\n" +
            "<h2><strong>Kirish</strong></h2>\n" +
            "<p>Dasturiy ta&rsquo;minot yaratishda <strong>ma&rsquo;lumotlar bazasi bilan ishlash</strong> eng muhim jihatlardan biri hisoblanadi. Java dasturlash tilida <strong>ma&rsquo;lumotlar bazasi</strong> bilan ishlash uchun ko&lsquo;plab yondashuvlar mavjud bo&lsquo;lib, <strong>Hibernate</strong> &ndash; eng mashhur <strong>ORM (Object-Relational Mapping)</strong> texnologiyalaridan biri hisoblanadi.</p>\n" +
            "<p>Agar siz <strong>Hibernate</strong> ni chuqur o&lsquo;rganmoqchi bo&lsquo;lsangiz, <strong>Videohub.uz</strong> platformasida <strong>o&lsquo;zbek tilidagi mukammal video darslar</strong> mavjud bo&lsquo;lib, ulardan foydalangan holda <strong>Hibernate</strong> ni noldan professional darajagacha o&lsquo;rganishingiz mumkin.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Hibernate nima?</strong></h2>\n" +
            "<p><strong>Hibernate</strong> &ndash; bu <strong>Java dasturlari</strong> uchun <strong>ma&rsquo;lumotlar bazasi</strong> bilan ishlashni soddalashtiradigan <strong>ochiq kodli</strong> <strong>ORM (Object-Relational Mapping)</strong> freymvork hisoblanadi. U <strong>JDBC (Java Database Connectivity)</strong> dan ustun bo&lsquo;lib, <strong>ma&rsquo;lumotlar bazasi operatsiyalarini avtomatlashtirish</strong> va kodni optimallashtirishga yordam beradi.</p>\n" +
            "<p>Hibernate <strong>SQL so&lsquo;rovlarini minimal kod bilan yozish</strong>, <strong>ma&rsquo;lumotlarni jadval shaklida emas, obyekt sifatida boshqarish</strong>, va <strong>turli ma&rsquo;lumotlar bazalari bilan moslashuvchan ishlash</strong> imkonini beradi.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Hibernate Framework ning asosiy afzalliklari</strong></h2>\n" +
            "<h3>✅ <strong>1. SQL yozish shart emas</strong></h3>\n" +
            "<p>Hibernate yordamida <strong>JPA (Java Persistence API)</strong> orqali <strong>obyektlar</strong> yordamida ma&rsquo;lumotlar bazasi bilan ishlash mumkin. <strong>SQL kod yozish zarurati yo&lsquo;q</strong>!</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>✅&nbsp;<strong>2. Platformaga bog&lsquo;liq emas</strong></h3>\n" +
            "<p>Hibernate <strong>MySQL, PostgreSQL, Oracle, Microsoft SQL Server</strong> kabi turli <strong>ma&rsquo;lumotlar bazalari bilan moslashuvchan</strong> ishlaydi.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>✅&nbsp;<strong>3. Kodni soddalashtiradi</strong></h3>\n" +
            "<p>An&rsquo;anaviy <strong>JDBC kodlari</strong> ko&lsquo;p joy egallaydi va murakkab bo&lsquo;lishi mumkin. Hibernate esa <strong>oddiy va ixcham kod</strong> yozish imkonini beradi.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>✅&nbsp;<strong>4. Avtomatik jadval yaratish va boshqarish</strong></h3>\n" +
            "<p>Hibernate <strong>ma&rsquo;lumotlar bazasida avtomatik ravishda jadval yaratishi, yangilashi va o&lsquo;zgartirishi</strong> mumkin.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>✅&nbsp;<strong>5. Yaxshiroq keshlash (Caching) tizimi</strong></h3>\n" +
            "<p>Hibernate <strong>birlamchi va ikkilamchi keshlash (First-level &amp; Second-level Caching)</strong> imkoniyatiga ega bo&lsquo;lib, <strong>tezkor ma&rsquo;lumot olish</strong> va <strong>bajariladigan so&lsquo;rovlarni optimallashtirish</strong> imkonini beradi.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3>✅&nbsp;<strong>6. Kodning mustahkamligi va moslashuvchanligi</strong></h3>\n" +
            "<p>Hibernate orqali yozilgan kod <strong>ma&rsquo;lumotlar bazasidan mustaqil</strong> bo&lsquo;lib, <strong>turli DBMS lar</strong> bilan ishlash imkonini beradi.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Hibernate qanday ishlaydi?</strong></h2>\n" +
            "<p>Hibernate <strong>ma&rsquo;lumotlar bazasi va Java obyektlari</strong> o&lsquo;rtasida <strong>ko&lsquo;prik vazifasini bajaradi</strong>.</p>\n" +
            "<p>1️⃣ <strong>Java sinfi (Entity Class)</strong> &ndash; Ma&rsquo;lumotlarni saqlash uchun <strong>obyekt</strong> yaratamiz.<br>2️⃣ <strong>Jadvalga bog&lsquo;lash (Annotations)</strong> &ndash; <strong>@Entity, @Table, @Id, @Column</strong> kabi annotatsiyalar yordamida <strong>obyekt va ma&rsquo;lumotlar bazasi jadvallarini bog&lsquo;laymiz</strong>.<br>3️⃣ <strong>Hibernate Configuration</strong> &ndash; <strong>hibernate.cfg.xml</strong> yoki <strong>application.properties</strong> fayllari orqali <strong>ma&rsquo;lumotlar bazasi sozlanadi</strong>.<br>4️⃣ <strong>SessionFactory va Session</strong> &ndash; Hibernate <strong>ma&rsquo;lumotlarni saqlash, yangilash va o&lsquo;chirish</strong> uchun <strong>Session API</strong> dan foydalanadi.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Hibernate asosiy komponentlari</strong></h2>\n" +
            "<h3><strong>1. SessionFactory</strong></h3>\n" +
            "<p><strong>SessionFactory</strong> Hibernate ning <strong>yadro qismi</strong> bo&lsquo;lib, <strong>ma&rsquo;lumotlar bazasi bilan ulanishni</strong> boshqaradi.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3><strong>2. Session</strong></h3>\n" +
            "<p><strong>Session</strong> &ndash; bu Hibernate ning <strong>asosiy interfeysi</strong> bo&lsquo;lib, <strong>ma&rsquo;lumotlarni yaratish, yangilash, o&lsquo;chirish va olish uchun ishlatiladi</strong>.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3><strong>3. Transaction</strong></h3>\n" +
            "<p><strong>Transaction</strong> &ndash; bu <strong>barcha ma&rsquo;lumotlar bazasi operatsiyalarini bitta blok sifatida bajarish</strong> imkonini beruvchi mexanizmdir.</p>\n" +
            "<h3>&nbsp;</h3>\n" +
            "<h3><strong>4. Query va Criteria API</strong></h3>\n" +
            "<p>Hibernate <strong>so&lsquo;rovlarni bajarish</strong> uchun <strong>HQL (Hibernate Query Language)</strong> va <strong>Criteria API</strong> ni qo&lsquo;llab-quvvatlaydi.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Hibernate ni qayerdan o&lsquo;rganish mumkin?</strong></h2>\n" +
            "<p>Agar siz <strong>Hibernate</strong> ni professional darajada o&lsquo;rganishni xohlasangiz, <strong>Videohub.uz</strong> platformasidagi <strong>o&lsquo;zbek tilidagi darslarni</strong> tavsiya qilamiz!</p>\n" +
            "<p>🎯 <strong>Videohub.uz da siz Hibernate ni noldan o&lsquo;rganishingiz mumkin:</strong><br>🔹 <strong>Hibernate asoslari</strong> &ndash; ORM va JPA haqida tushunchalar<br>🔹 <strong>Entity va Annotatsiyalar</strong> &ndash; @Entity, @Table, @Column, @Id, @GeneratedValue<br>🔹 <strong>Ma&rsquo;lumotlar bazasi bilan ishlash</strong> &ndash; CRUD operatsiyalari<br>🔹 <strong>Hibernate Query Language (HQL)</strong> &ndash; SQL so&lsquo;rovlarini optimallashtirish<br>🔹 <strong>Keshlash (Caching)</strong> &ndash; Ma&rsquo;lumotlarni tezroq olish</p>\n" +
            "<p>Bundan tashqari, <strong>Dasturlash.uz</strong> saytida <strong>Hibernate bo&lsquo;yicha o&lsquo;zbek tilida maqolalar</strong> mavjud bo&lsquo;lib, siz <strong>qo&lsquo;shimcha nazariy bilimlarga</strong> ega bo&lsquo;lishingiz mumkin.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Xulosa</strong></h2>\n" +
            "<p><strong>Hibernate</strong> &ndash; bu <strong>Java dasturchilar</strong> uchun <strong>ma&rsquo;lumotlar bazasi</strong> bilan ishlashni osonlashtiruvchi kuchli vositadir. Agar siz <strong>ORM, JPA, Spring Data JPA</strong> kabi texnologiyalarni o&lsquo;rganmoqchi bo&lsquo;lsangiz, <strong>Hibernate</strong> bilan boshlash eng yaxshi tanlovdir.</p>\n" +
            "<p>✅ <strong>Videohub.uz</strong> platformasida <strong>o&lsquo;zbek tilidagi ajoyib Hibernate darslari</strong> mavjud.<br>✅ <strong>Dasturlash.uz</strong> saytida Hibernate haqida maqolalar o&lsquo;qib, bilimlaringizni mustahkamlashingiz mumkin.</p>\n" +
            "<p>Agar siz <strong>Java backend dasturchisi</strong> bo&lsquo;lishni xohlasangiz, hoziroq <strong>Hibernate ni o&lsquo;rganishni boshlang! 🚀</strong></p>"    },
    {
        id: "dasturchi-bo‘lish-qadamlari",
        title: "Dasturchi bo‘lish qadamlari",
        img: "https://api.videohub.uz/api/v1/attach/open-all/23fa425a-8446-4d93-ade5-d96f831a0a77",
        description: "Dasturchi bo‘lish zamonaviy kasblardan biri bo‘lib, u yuqori maosh va ijodiy rivojlanish imkoniyatlarini taqdim etadi. Texnologiyaning jadal rivojlanishi natijasida dasturchilarga bo‘lgan talab oshib bormoqda. Ushbu maqolada dasturchi bo‘lish yo‘lidagi asosiy bosqichlar va Videohub.uz platformasi orqali qanday qilib muvaffaqiyatli dasturchi bo‘lish mumkinligi haqida so‘z yuritamiz.",
        content: "<h1>&nbsp;</h1>\n" +
            "<h1><strong>Dasturchi bo&lsquo;lishning asosiy qadamlari</strong></h1>\n" +
            "<h2><strong>Kirish</strong></h2>\n" +
            "<p>Bugungi kunda <strong>dasturchi bo&lsquo;lish</strong> zamonaviy va istiqbolli kasblardan biri hisoblanadi. Dasturchilik <strong>ijodkorlik, mantiqiy fikrlash va muammolarni hal qilish qobiliyatlarini</strong> talab qiladi. Ushbu maqolada <strong>noldan dasturchi bo&lsquo;lish</strong> uchun asosiy qadamlarni ko&lsquo;rib chiqamiz va <strong>Videohub.uz</strong> platformasida mavjud bo&lsquo;lgan <strong>o&lsquo;zbek tilidagi ajoyib darslar</strong> yordamida qanday qilib dasturchi bo&lsquo;lish mumkinligi haqida ma&rsquo;lumot beramiz.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>1-qadam: Dasturlash tilini tanlash</strong></h2>\n" +
            "<p>Dasturchi bo&lsquo;lish uchun <strong>birinchi qadam</strong> &ndash; <strong>dasturlash tilini tanlashdir</strong>. Quyidagi mashhur dasturlash tillari mavjud:</p>\n" +
            "<ul>\n" +
            "  <li><strong>Python</strong> &ndash; Yangi boshlovchilar uchun eng mos til. Sun&rsquo;iy intellekt va veb dasturlashda keng qo&lsquo;llaniladi.</li>\n" +
            "  <li><strong>Java</strong> &ndash; <strong>Katta tizimlar, Android ilovalar</strong> yaratishda ishlatiladi.</li>\n" +
            "  <li><strong>JavaScript</strong> &ndash; <strong>Frontend va Backend (Node.js)</strong> dasturlashda qo&lsquo;llanadi.</li>\n" +
            "  <li><strong>C++</strong> &ndash; <strong>O&lsquo;yinlar, tizim dasturlash va algoritmlar</strong> uchun juda yaxshi tanlov.</li>\n" +
            "  <li><strong>Go, Rust, Kotlin</strong> &ndash; Yangi zamonaviy dasturlash tillari.</li>\n" +
            "</ul>\n" +
            "<p><strong>Maslahat:</strong> <strong>Videohub.uz</strong> platformasida <strong>Java, Python va JavaScript bo&lsquo;yicha boshlang&lsquo;ich va professional darslar</strong> mavjud.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>2-qadam: Asosiy dasturlash tushunchalarini o&lsquo;rganish</strong></h2>\n" +
            "<p>Dasturlash tilini tanlaganingizdan so&lsquo;ng, <strong>quyidagi asosiy tushunchalarni o&lsquo;rganish kerak</strong>:</p>\n" +
            "<p>✅ <strong>O&lsquo;zgaruvchilar (Variables)</strong><br>✅ <strong>Ma&rsquo;lumot turlari (Data Types)</strong><br>✅ <strong>Shart operatorlari (if-else, switch-case)</strong><br>✅ <strong>Sikl operatorlari (for, while, do-while)</strong><br>✅ <strong>Funktsiyalar va metodlar (Functions, Methods)</strong><br>✅ <strong>Ma&rsquo;lumotlar tuzilmalari (Arrays, Lists, HashMap)</strong><br>✅ <strong>Obyektga yo&lsquo;naltirilgan dasturlash (OOP &ndash; Java, Python, C++)</strong></p>\n" +
            "<p>Agar siz <strong>Java dasturlash tilini</strong> o&lsquo;rganmoqchi bo&lsquo;lsangiz, <strong>Videohub.uz</strong> platformasida:</p>\n" +
            "<ul>\n" +
            "  <li><strong>Java Foundation</strong> &ndash; Java ning asoslari</li>\n" +
            "  <li><strong>Java OOP</strong> &ndash; Obyektga yo&lsquo;naltirilgan dasturlash</li>\n" +
            "  <li><strong>Java Spring</strong> &ndash; Backend dasturlash kurslari mavjud.</li>\n" +
            "</ul>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>3-qadam: Amaliyot va kichik loyihalar</strong></h2>\n" +
            "<p>Nazariyani o&lsquo;rganish yetarli emas! <strong>Kod yozish va loyihalar yaratish</strong> orqali o&lsquo;rganish jarayonini mustahkamlash kerak. Quyidagi kichik loyihalarni sinab ko&lsquo;rishingiz mumkin:</p>\n" +
            "<p>🚀 <strong>Kalkulyator</strong> &ndash; oddiy arifmetik amallar bajara oladigan dastur yozish.<br>🚀 <strong>To-do list</strong> &ndash; vazifalarni qo&lsquo;shish, o&lsquo;chirish va tahrirlash imkoniyatiga ega bo&lsquo;lgan dastur yaratish.<br>🚀 <strong>Web sahifa</strong> &ndash; HTML, CSS va JavaScript yordamida oddiy veb-sayt yaratish.<br>🚀 <strong>Ma&rsquo;lumotlar bazasi bilan ishlash</strong> &ndash; MySQL yoki PostgreSQL bilan CRUD operatsiyalarini bajarish.</p>\n" +
            "<p><strong>Maslahat:</strong> <strong>Videohub.uz</strong> da <strong>praktik darslar</strong> va <strong>real loyihalar asosida kurslar</strong> mavjud.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>4-qadam: Git va GitHub bilan ishlash</strong></h2>\n" +
            "<p>Professional dasturchilar <strong>Git</strong> orqali o&lsquo;z kodlarini boshqaradilar.</p>\n" +
            "<ul>\n" +
            "  <li><strong>Git</strong> &ndash; kod versiyalarini saqlash va boshqarish vositasi.</li>\n" +
            "  <li><strong>GitHub / GitLab / Bitbucket</strong> &ndash; kodni onlayn saqlash va boshqalar bilan ishlash imkoniyatini beruvchi platformalar.</li>\n" +
            "</ul>\n" +
            "<p><strong>Amaliyot:</strong><br>1️⃣ <strong>Git o&lsquo;rnatish</strong> &ndash; GitHub hisobini ochish.<br>2️⃣ <strong>Loyiha yaratish va commit qilish</strong>.<br>3️⃣ <strong>GitHub orqali kod almashish</strong>.</p>\n" +
            "<p><strong>Maslahat:</strong> <strong>Videohub.uz</strong> da <strong>Git va GitHub bo&lsquo;yicha to&lsquo;liq darsliklar</strong> mavjud.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>5-qadam: Ma&rsquo;lumotlar bazasi va Backend texnologiyalarini o&lsquo;rganish</strong></h2>\n" +
            "<p>Dasturiy ta&rsquo;minotda <strong>ma&rsquo;lumotlar bazasi</strong> juda muhim rol o&lsquo;ynaydi. Quyidagi ma&rsquo;lumotlar bazalarini o&lsquo;rganish tavsiya etiladi:</p>\n" +
            "<ul>\n" +
            "  <li><strong>MySQL</strong> &ndash; Eng ommabop ma&rsquo;lumotlar bazasi.</li>\n" +
            "  <li><strong>PostgreSQL</strong> &ndash; Murakkab loyihalar uchun qulay.</li>\n" +
            "  <li><strong>MongoDB</strong> &ndash; NoSQL ma&rsquo;lumotlar bazasi, <strong>Node.js</strong> bilan yaxshi ishlaydi.</li>\n" +
            "</ul>\n" +
            "<p>Agar siz <strong>Backend dasturchi bo&lsquo;lishni xohlasangiz</strong>, quyidagi texnologiyalarni o&lsquo;rganish kerak:</p>\n" +
            "<p>🔥 <strong>Spring Boot</strong> &ndash; Java backend dasturlash freymvorki.<br>🔥 <strong>Django</strong> &ndash; Python bilan backend yaratish.<br>🔥 <strong>Node.js</strong> &ndash; JavaScript bilan backend dasturlash.</p>\n" +
            "<p><strong>Videohub.uz</strong> da <strong>Spring Boot, Hibernate, REST API</strong> kabi backend texnologiyalarini o&lsquo;rganish mumkin.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>6-qadam: Tajriba orttirish va ish topish</strong></h2>\n" +
            "<p>Dasturchi bo&lsquo;lish uchun <strong>real tajriba</strong> kerak. Quyidagilarni bajaring:</p>\n" +
            "<p>🎯 <strong>Freelance loyihalar ustida ishlang</strong> &ndash; Upwork, Fiverr, Toptal kabi platformalarda dasturchi sifatida ishlashga harakat qiling.<br>🎯 <strong>Portfolio yarating</strong> &ndash; GitHub da o&lsquo;z loyihalaringizni yuklang.<br>🎯 <strong>Intervyu tayyorlang</strong> &ndash; Algoritmlar va dasturlash savollariga javob berishni mashq qiling.</p>\n" +
            "<p><strong>Videohub.uz</strong> da <strong>freelance ishlash va intervyu savollari bo&lsquo;yicha darslar</strong> mavjud!</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Xulosa</strong></h2>\n" +
            "<p>Dasturchi bo&lsquo;lish <strong>uzluksiz o&lsquo;rganish</strong> va <strong>amaliyot qilish</strong> orqali erishiladigan kasbdir. Quyidagi bosqichlarni bajarsangiz, dasturchi bo&lsquo;lishingiz aniq:</p>\n" +
            "<p>✔ <strong>Dasturlash tilini tanlash (Java, Python, JavaScript)</strong><br>✔ <strong>Asosiy tushunchalarni o&lsquo;rganish (OOP, algoritmlar, ma&rsquo;lumot tuzilmalari)</strong><br>✔ <strong>Loyihalar qilish va GitHub da portfoliongizni yaratish</strong><br>✔ <strong>Ma&rsquo;lumotlar bazalari va backend texnologiyalarini o&lsquo;rganish</strong><br>✔ <strong>Tajriba orttirish va freelance yoki to&lsquo;liq stavkali ish topish</strong></p>\n" +
            "<p>🎯 <strong>Videohub.uz</strong> &ndash; o&lsquo;zbek tilida eng zo&lsquo;r <strong>Java, Python, Spring Boot, Hibernate, ma&rsquo;lumotlar bazasi</strong> va boshqa texnologiyalarni <strong>o&lsquo;rganish uchun mukammal platforma</strong>!</p>\n" +
            "<p><strong>Dasturchi bo&lsquo;lishni istasangiz &ndash; hoziroq boshlang! 🚀</strong></p>"},
    {
        id: "dasturlashni-noldan-o‘rganish",
        title: "Dasturlashni noldan o‘rganish",
        img: "https://api.videohub.uz/api/v1/attach/open-all/e41bdd85-9692-4e08-92d9-d36ba0ca0649",
        description: "Bugungi kunda dasturlash – eng istiqbolli va talabgir sohalardan biri hisoblanadi. Ko‘plab yangi boshlovchilar dasturlashni mustaqil ravishda o‘rganishni xohlashadi, biroq qayerdan boshlash kerakligini bilmaydilar. Ushbu maqolada dasturlashni noldan o‘rganish bosqichlari va Videohub.uz platformasi yordamida qanday qilib samarali ta’lim olish mumkinligi haqida gapiramiz.",
        content: "<h1>&nbsp;</h1>\n" +
            "<h1><strong>Dasturlashni Noldan O&lsquo;rganish: Boshlang&lsquo;ichlar Uchun Qo&lsquo;llanma</strong></h1>\n" +
            "<h2><strong>Kirish</strong></h2>\n" +
            "<p>Dasturlash &ndash; bu texnologik dunyoning eng muhim sohalaridan biri bo&lsquo;lib, u orqali veb-saytlar, mobil ilovalar, sun&rsquo;iy intellekt tizimlari va boshqa ko&lsquo;plab dasturiy mahsulotlar yaratiladi. Agar siz <strong>dasturlashni noldan o&lsquo;rganmoqchi</strong> bo&lsquo;lsangiz, to&lsquo;g&lsquo;ri yo&lsquo;ldasiz! Ushbu maqolada <strong>qayerdan boshlash, qanday dasturlash tillarini tanlash va qanday platformalardan foydalanish</strong> haqida gaplashamiz.</p>\n" +
            "<p>Bundan tashqari, <strong>Videohub.uz</strong> va <strong>Dasturlash.uz</strong> platformalarida <strong>o&lsquo;zbek tilida bepul va pullik video darslar hamda maqolalar mavjud</strong> bo&lsquo;lib, siz ulardan foydalanishingiz mumkin.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>1-qadam: Maqsadingizni Aniq Belgilang</strong></h2>\n" +
            "<p>Dasturlashni o&lsquo;rganishdan oldin <strong>qanday yo&lsquo;nalishda ishlamoqchi ekanligingizni aniqlab olish muhim</strong>:</p>\n" +
            "<p>🔹 <strong>Veb-dasturlash</strong> &ndash; veb-saytlar va web-ilovalar yaratish (Frontend va Backend)<br>🔹 <strong>Mobil dasturlash</strong> &ndash; Android va iOS ilovalar yaratish<br>🔹 <strong>Sun&rsquo;iy intellekt va ma&rsquo;lumotlar ilmi</strong> &ndash; AI va Data Science<br>🔹 <strong>O&lsquo;yin yaratish</strong> &ndash; C++ va Unity bilan 3D o&lsquo;yinlar ishlab chiqish<br>🔹 <strong>Korporativ dasturlash</strong> &ndash; Java, .NET kabi texnologiyalar bilan biznes dasturlar yaratish</p>\n" +
            "<p>Agar siz faqat qiziqish uchun o&lsquo;rganmoqchi bo&lsquo;lsangiz ham, bir yo&lsquo;nalishni tanlash tavsiya etiladi.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>2-qadam: Dasturlash Tilini Tanlash</strong></h2>\n" +
            "<p>Dasturlashni boshlash uchun birinchi navbatda <strong>mos keladigan tilni tanlash</strong> kerak. Quyida <strong>yangi boshlovchilar uchun eng yaxshi dasturlash tillari</strong> keltirilgan:</p>\n" +
            "<table>\n" +
            "  <thead>\n" +
            "    <tr>\n" +
            "      <th><strong>Dasturlash Tili</strong></th>\n" +
            "      <th><strong>Yo&lsquo;nalishi</strong></th>\n" +
            "      <th><strong>Osonligi</strong></th>\n" +
            "    </tr>\n" +
            "  </thead>\n" +
            "  <tbody>\n" +
            "    <tr>\n" +
            "      <td><strong>Python</strong></td>\n" +
            "      <td>Sun&rsquo;iy intellekt, veb dasturlash</td>\n" +
            "      <td>Juda oson ✅</td>\n" +
            "    </tr>\n" +
            "    <tr>\n" +
            "      <td><strong>JavaScript</strong></td>\n" +
            "      <td>Veb dasturlash (Frontend va Backend)</td>\n" +
            "      <td>Oson ✅</td>\n" +
            "    </tr>\n" +
            "    <tr>\n" +
            "      <td><strong>Java</strong></td>\n" +
            "      <td>Mobil dasturlash (Android) va korporativ tizimlar</td>\n" +
            "      <td>O&lsquo;rta daraja 🔥</td>\n" +
            "    </tr>\n" +
            "    <tr>\n" +
            "      <td><strong>C++</strong></td>\n" +
            "      <td>O&lsquo;yin va tizim dasturlash</td>\n" +
            "      <td>Murakkab 😰</td>\n" +
            "    </tr>\n" +
            "  </tbody>\n" +
            "</table>\n" +
            "<p><strong>Maslahat:</strong> Agar siz dasturlashni tezroq tushunmoqchi bo&lsquo;lsangiz, <strong>Python yoki JavaScript</strong> dan boshlash tavsiya etiladi. Agar <strong>kuchli backend dasturchi</strong> bo&lsquo;lishni istasangiz, <strong>Java va Spring Boot</strong> ni o&lsquo;rganish yaxshi tanlov bo&lsquo;ladi.</p>\n" +
            "<p>📌 <strong>Videohub.uz</strong> platformasida <strong>Java, Python, JavaScript bo&lsquo;yicha to&lsquo;liq video darslar mavjud</strong>.</p>\n" +
            "<p>📌 <strong>Dasturlash.uz</strong> saytida esa <strong>dasturlash bo&lsquo;yicha maqolalar va darsliklar</strong> bor.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>3-qadam: Asosiy Dasturlash Konseptlarini O&lsquo;rganish</strong></h2>\n" +
            "<p>Tanlangan dasturlash tilidan qat&rsquo;i nazar, siz <strong>quyidagi tushunchalarni o&lsquo;rganishingiz shart</strong>:</p>\n" +
            "<p>✅ <strong>Ma&rsquo;lumot turlari va o&lsquo;zgaruvchilar (Variables, Data Types)</strong><br>✅ <strong>Shart operatorlari (If-Else, Switch-Case)</strong><br>✅ <strong>Tsikllar (For, While, Do-While)</strong><br>✅ <strong>Funksiyalar (Functions, Methods)</strong><br>✅ <strong>OOP (Obyektga yo&lsquo;naltirilgan dasturlash: Class, Object, Inheritance, Polymorphism)</strong><br>✅ <strong>Ma&rsquo;lumotlar bazasi va SQL</strong></p>\n" +
            "<p>Ushbu tushunchalarni o&lsquo;rganish uchun <strong>Videohub.uz va Dasturlash.uz</strong> da mavjud bo&lsquo;lgan <strong>Java Foundation, Java OOP va Python kurslari</strong> sizga yordam beradi.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>4-qadam: Amaliy Mashg&lsquo;ulotlar va Loyihalar</strong></h2>\n" +
            "<p>Dasturlashni faqat <strong>nazariy bilimlar</strong> bilan o&lsquo;rganib bo&lsquo;lmaydi! <strong>Hafta davomida kamida 5-10 soat amaliy kod yozish</strong> tavsiya etiladi. Amaliyot uchun quyidagilarni bajarishingiz mumkin:</p>\n" +
            "<p>🚀 <strong>Oddiy dasturlar yaratish</strong>: Kalkulyator, To-do list, O&lsquo;yinlar<br>🚀 <strong>Loyiha qilish</strong>: Veb-sayt yoki mobil ilova yaratish<br>🚀 <strong>Online platformalarda mashq qilish</strong>: Leetcode, Codeforces, HackerRank</p>\n" +
            "<p>📌 <strong>Videohub.uz</strong> da real loyihalar orqali o&lsquo;qitiladigan <strong>praktik kurslar</strong> mavjud.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>5-qadam: Git va GitHub O&lsquo;rganish</strong></h2>\n" +
            "<p>Professional dasturchilar <strong>Git va GitHub</strong> dan foydalanishadi. Bu kodni boshqarish va jamoaviy ishlash uchun zarur bo&lsquo;lgan texnologiyadir.</p>\n" +
            "<p><strong>Asosiy Git buyruqlari:</strong><br>✔ <code>git init</code> &ndash; Yangi loyiha yaratish<br>✔ <code>git add .</code> &ndash; O&lsquo;zgarishlarni qo&lsquo;shish<br>✔ <code>git commit -m \"Dastlabki commit\"</code> &ndash; O&lsquo;zgarishlarni saqlash<br>✔ <code>git push</code> &ndash; Kodni GitHub ga yuklash</p>\n" +
            "<p><strong>Maslahat:</strong> GitHub da o&lsquo;z loyihalaringizni saqlab boring &ndash; bu sizning <strong>portfolioingizni yaratishda</strong> juda muhim.</p>\n" +
            "<p>📌 <strong>Videohub.uz</strong> da <strong>Git va GitHub bo&lsquo;yicha to&lsquo;liq video darslar mavjud</strong>.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>6-qadam: Ma&rsquo;lumotlar Bazasi va Backend O&lsquo;rganish</strong></h2>\n" +
            "<p>Agar siz <strong>veb yoki mobil dasturchi</strong> bo&lsquo;lishni istasangiz, <strong>ma&rsquo;lumotlar bazalari va backend</strong> bilan ishlashni ham o&lsquo;rganishingiz kerak.</p>\n" +
            "<p>🔹 <strong>Ma&rsquo;lumotlar bazasi:</strong><br>✅ MySQL &ndash; Eng mashhur SQL bazasi<br>✅ PostgreSQL &ndash; Kuchli va kengaytiriladigan SQL bazasi<br>✅ MongoDB &ndash; NoSQL bazasi</p>\n" +
            "<p>🔹 <strong>Backend texnologiyalari:</strong><br>✅ <strong>Spring Boot</strong> &ndash; Java backend dasturlash freymvorki<br>✅ <strong>Django</strong> &ndash; Python bilan backend yaratish<br>✅ <strong>Node.js</strong> &ndash; JavaScript bilan backend yaratish</p>\n" +
            "<p>📌 <strong>Videohub.uz</strong> da <strong>Spring Boot, Hibernate, REST API, ma&rsquo;lumotlar bazalari</strong> bo&lsquo;yicha darsliklar mavjud.</p>\n" +
            "<p>📌 <strong>Dasturlash.uz</strong> da esa <strong>o&lsquo;zbek tilidagi maqolalar</strong> bor.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>7-qadam: Ish Topish va Portfolio Yaratish</strong></h2>\n" +
            "<p>Agar siz <strong>mustaqil dasturchi sifatida ishlamoqchi</strong> bo&lsquo;lsangiz yoki kompaniyada ishlashni rejalashtirsangiz, <strong>portfolio yaratish</strong> juda muhim. Quyidagi tavsiyalarni bajaring:</p>\n" +
            "<p>🎯 <strong>Freelance saytlariga kiring</strong>: Upwork, Fiverr, Toptal<br>🎯 <strong>Portfolio yarating</strong>: GitHub da loyihalaringizni joylashtiring<br>🎯 <strong>Ish intervyulariga tayyorgarlik ko&lsquo;ring</strong>: Algoritmlar va ma&rsquo;lumot tuzilmalari bo&lsquo;yicha mashq qiling</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Xulosa</strong></h2>\n" +
            "<p>Dasturlashni o&lsquo;rganish vaqt va mehnat talab qiladi. Quyidagi bosqichlarni bajarsangiz, dasturchi bo&lsquo;lishingiz aniq:</p>\n" +
            "<p>✔ <strong>Dasturlash tilini tanlash (Java, Python, JavaScript)</strong><br>✔ <strong>Asosiy tushunchalarni o&lsquo;rganish (OOP, algoritmlar, ma&rsquo;lumotlar bazalari)</strong><br>✔ <strong>Amaliy mashg&lsquo;ulotlar bajarish va loyihalar yaratish</strong><br>✔ <strong>Git, GitHub va backend texnologiyalarini o&lsquo;rganish</strong><br>✔ <strong>Portfolio yaratish va ish topish</strong></p>\n" +
            "<p>🎯 <strong>Videohub.uz va Dasturlash.uz</strong> &ndash; o&lsquo;zbek tilida eng zo&lsquo;r <strong>Java, Python, Spring Boot, Hibernate</strong> va boshqa texnologiyalarni <strong>o&lsquo;rganish uchun mukammal platformalar</strong>!</p>\n" +
            "<p>🚀 <strong>Dasturchi bo&lsquo;lishni hoziroq boshlang!</strong></p>"},
    {
        id: "spring-boot-haqida",
        title: "Spring Boot haqida",
        img: "https://api.videohub.uz/api/v1/attach/open-all/73e5c911-4bf0-4dfc-be4b-91effe74a811",
        description: "Spring Boot — bu Java ekotizimida backend dasturlarini tez va oson yaratish uchun mo‘ljallangan framework. U Spring Framework asosida qurilgan bo‘lib, konfiguratsiyani minimal darajaga tushiradi va ishlab chiqish jarayonini soddalashtiradi. Ushbu maqolada Spring Boot’ning afzalliklari, imkoniyatlari va uni o‘rganish uchun eng yaxshi manbalar, jumladan Videohub.uz va Dasturlash.uz haqida ma’lumot beramiz.",
        content: "<h1><strong>Spring Boot: Zamonaviy Java Backend Dasturlash</strong></h1>\n" +
            "<h2><strong>Kirish</strong></h2>\n" +
            "<p>Spring Boot &ndash; bu <strong>Java dasturchilarining backend tizimlarini tez va samarali yaratishiga yordam beruvchi framework</strong> bo&lsquo;lib, u Spring ekotizimining eng mashhur va qulay qismlaridan biridir. U <strong>minimal konfiguratsiya</strong> va <strong>tayyor modullar</strong> yordamida <strong>murakkab web-ilovalarni oson ishlab chiqish</strong> imkonini beradi.</p>\n" +
            "<p>Agar siz Spring Boot ni <strong>boshidan boshlab o&lsquo;rganmoqchi bo&lsquo;lsangiz</strong>, <strong>Videohub.uz</strong> siz uchun <strong>ajoyib imkoniyatlar</strong> taqdim etadi. Ushbu platformada <strong>Spring Boot, Spring Core, Spring Security, Spring Data JPA va Spring REST</strong> bo&lsquo;yicha <strong>o&lsquo;zbek tilida to&lsquo;liq video kurslar mavjud</strong>.</p>\n" +
            "<p>Bundan tashqari, <strong>Dasturlash.uz</strong> saytidan <strong>Spring Boot haqida o&lsquo;zbek tilidagi maqolalar</strong> va amaliy kod misollarini topishingiz mumkin.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Spring Boot nima va u nima uchun kerak?</strong></h2>\n" +
            "<p>Spring Boot &ndash; bu <strong>Spring Framework asosida yaratilgan, lekin undan ancha soddalashtirilgan</strong> framework bo&lsquo;lib, u quyidagi jihatlari bilan ajralib turadi:</p>\n" +
            "<p>🔹 <strong>Tez ishga tushish:</strong> Tayyor konfiguratsiyalar orqali dasturlarni tez ishlab chiqish<br>🔹 <strong>Kamroq kod va sozlash:</strong> XML konfiguratsiyaga ehtiyoj yo&lsquo;q<br>🔹 <strong>O&lsquo;rnatilgan server:</strong> Tomcat, Jetty kabi serverlar avtomatik integratsiyalashgan<br>🔹 <strong>Microservices arxitekturasi uchun ideal</strong></p>\n" +
            "<p>Spring Boot yordamida <strong>veb-ilovalar, REST API lar va korporativ biznes tizimlari</strong> oson yaratiladi.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Spring Boot asosiy xususiyatlari</strong></h2>\n" +
            "<h3>1️⃣ <strong>Starter va Dependencies (Bog&lsquo;liqliklar)</strong></h3>\n" +
            "<p>Spring Boot <strong>Starter</strong> lar yordamida turli modullarni oson integratsiya qilish mumkin. Masalan:</p>\n" +
            "<pre><code>&lt;dependency&gt;\n" +
            "    &lt;groupId&gt;org.springframework.boot&lt;/groupId&gt;\n" +
            "    &lt;artifactId&gt;spring-boot-starter-web&lt;/artifactId&gt;\n" +
            "    &lt;version&gt;3.0.0&lt;/version&gt;\n" +
            "&lt;/dependency&gt;\n" +
            "</code></pre>\n" +
            "<p>Bu <strong>Spring Boot Web</strong> modulini qo&lsquo;shadi va siz <strong>REST API yoki MVC ilovalarini ishlab chiqishingiz</strong> mumkin.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h3>2️⃣ <strong>O&lsquo;rnatilgan Tomcat Server</strong></h3>\n" +
            "<p>Spring Boot dasturlari <strong>alohida server sozlamalarisiz</strong> ishlaydi, chunki <strong>Tomcat</strong> yoki <strong>Jetty</strong> serverlari <strong>o&lsquo;rnatilgan holda keladi</strong>. Bu shuni anglatadiki, <strong>dasturingizni oddiy JAR fayl sifatida ishga tushirishingiz</strong> mumkin.</p>\n" +
            "<pre><code>mvn spring-boot:run\n" +
            "</code></pre>\n" +
            "<p>Ushbu buyruq <strong>dasturingizni avtomatik serverda ishga tushiradi</strong>.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h3>3️⃣ <strong>Spring Boot Configuration (sozlamalar)</strong></h3>\n" +
            "<p>Spring Boot sozlamalar uchun <strong>application.properties yoki application.yml</strong> faylidan foydalanadi. Masalan:</p>\n" +
            "<pre><code>server.port=8081\n" +
            "spring.datasource.url=jdbc:<a href=\"mysql://localhost:3306/mydb\">mysql://localhost:3306/mydb</a>\n" +
            "spring.datasource.username=root\n" +
            "spring.datasource.password=root\n" +
            "</code></pre>\n" +
            "<p>Bu orqali <strong>portni o&lsquo;zgartirish yoki ma&rsquo;lumotlar bazasi bilan bog&lsquo;lanish</strong> juda oson bo&lsquo;ladi.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h3>4️⃣ <strong>REST API yaratish (Controller)</strong></h3>\n" +
            "<p>Spring Boot da <strong>oddiy REST API</strong> yaratish juda oson:</p>\n" +
            "<pre><code>@RestController\n" +
            "@RequestMapping(\"/api\")\n" +
            "public class HelloController {\n" +
            "\n" +
            "    @GetMapping(\"/hello\")\n" +
            "    public String sayHello() {\n" +
            "        return \"Salom, Spring Boot!\";\n" +
            "    }\n" +
            "}\n" +
            "</code></pre>\n" +
            "<p>Bu kod <code>/api/hello</code> manziliga kirganda <strong>\"Salom, Spring Boot!\"</strong> degan matnni qaytaradi.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Spring Boot modullari va ularni o&lsquo;rganish</strong></h2>\n" +
            "<p><strong>Agar siz Spring Boot ni puxta o&lsquo;rganmoqchi bo&lsquo;lsangiz</strong>, <strong>Videohub.uz</strong> va <strong>Dasturlash.uz</strong> da quyidagi modullar haqida mukammal darsliklar va maqolalar mavjud:</p>\n" +
            "<h3><strong>1️⃣ Spring Core</strong></h3>\n" +
            "<p>Spring Framework ning asosiy qismi bo&lsquo;lib, <strong>Dependency Injection va Inversion of Control (IoC)</strong> ga asoslangan.</p>\n" +
            "<p>📌 <strong>Videohub.uz</strong> da <strong>Spring Core bo&lsquo;yicha chuqur darslar mavjud.</strong></p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h3><strong>2️⃣ Spring Boot</strong></h3>\n" +
            "<p>Spring Core ning soddalashtirilgan versiyasi bo&lsquo;lib, <strong>REST API larni tez yaratishga yordam beradi</strong>.</p>\n" +
            "<p>📌 <strong>Videohub.uz</strong> da <strong>Spring Boot bo&lsquo;yicha to&lsquo;liq video kurslar mavjud.</strong></p>\n" +
            "<p>📌 <strong>Dasturlash.uz</strong> da <strong>Spring Boot haqida maqolalar va amaliy misollar bor.</strong></p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h3><strong>3️⃣ Spring Security</strong></h3>\n" +
            "<p>Dasturlarning xavfsizligini ta&rsquo;minlaydi va <strong>avtorizatsiya (authentication) va huquqlarni boshqarish (authorization) mexanizmlarini</strong> taqdim etadi.</p>\n" +
            "<p>📌 <strong>Videohub.uz</strong> da <strong>Spring Security bo&lsquo;yicha mukammal darslar mavjud.</strong></p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h3><strong>4️⃣ Spring Data JPA</strong></h3>\n" +
            "<p>Ma&rsquo;lumotlar bazasi bilan ishlashni soddalashtirish uchun foydalaniladi.</p>\n" +
            "<p>📌 <strong>Videohub.uz</strong> da <strong>Spring Data JPA bo&lsquo;yicha darslar bor.</strong></p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h3><strong>5️⃣ Spring REST</strong></h3>\n" +
            "<p>REST API yaratish va JSON formatida ma&rsquo;lumot almashishni osonlashtiradi.</p>\n" +
            "<p>📌 <strong>Videohub.uz</strong> da <strong>Spring REST bo&lsquo;yicha ham darslar mavjud.</strong></p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Spring Boot ni qayerdan o&lsquo;rganish mumkin?</strong></h2>\n" +
            "<p>Agar siz Spring Boot ni <strong>boshidan boshlab, to&lsquo;liq professional darajaga qadar o&lsquo;rganmoqchi bo&lsquo;lsangiz</strong>, quyidagi manbalar aynan siz uchun:</p>\n" +
            "<p>📌 <strong>Videohub.uz</strong> &ndash; <strong>Spring Boot, Spring Core, Spring Security, Spring Data JPA, Spring REST</strong> bo&lsquo;yicha <strong>o&lsquo;zbek tilida mukammal video kurslar</strong> bor.</p>\n" +
            "<p>📌 <strong>Dasturlash.uz</strong> &ndash; <strong>Spring Boot haqida o&lsquo;zbek tilida maqolalar va darsliklar</strong> mavjud.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Xulosa</strong></h2>\n" +
            "<p>✅ <strong>Spring Boot</strong> &ndash; bu <strong>Java backend dasturlashni tezlashtiruvchi va soddalashtiruvchi framework</strong>.<br>✅ <strong>O&lsquo;rnatilgan serverlar, avtomatik konfiguratsiya va starter paketlar</strong> uni <strong>tezkor va samarali</strong> qiladi.<br>✅ <strong>REST API larni yaratish, ma&rsquo;lumotlar bazasi bilan ishlash va xavfsizlikni ta&rsquo;minlash</strong> Spring Boot yordamida juda oson.<br>✅ <strong>Videohub.uz</strong> da <strong>Spring Boot va uning barcha modullari bo&lsquo;yicha chuqur o&lsquo;zbek tilidagi video darslar mavjud</strong>.<br>✅ <strong>Dasturlash.uz</strong> da <strong>Spring Boot bo&lsquo;yicha maqolalar va amaliy misollar bor</strong>.</p>\n" +
            "<p>🚀 <strong>Spring Boot ni o&lsquo;rganing va professional backend dasturchi bo&lsquo;lish sari ilk qadamni qo&lsquo;ying!</strong></p>"  },
    {
        id: "java-OOP-tushunchalari",
        title: "Java OOP tushunchalari",
        img: "https://api.videohub.uz/api/v1/attach/open-all/a00620f4-fc86-497f-8d41-887494c1f3f0",
        description: "Obyektga yo‘naltirilgan dasturlash (OOP – Object-Oriented Programming) Java dasturlash tilining asosiy tamoyillaridan biri hisoblanadi. OOP yondashuvi dasturlarni modulli, qayta ishlatilishi mumkin bo‘lgan va kengaytirish oson bo‘lgan tarkibga ajratishga imkon beradi. Ushbu maqolada Java OOP tushunchalarini batafsil tushuntirib, ularning qanday ishlashini misollar bilan ko‘rib chiqamiz.",
        content: "<h1>&nbsp;</h1>\n" +
            "<h1><strong>Java OOP (Obyektga yo&lsquo;naltirilgan dasturlash) tushunchalari</strong></h1>\n" +
            "<p>&nbsp;</p>\n" +
            "<h2><strong>Kirish</strong></h2>\n" +
            "<p>Java &ndash; <strong>obyektga yo&lsquo;naltirilgan dasturlash (OOP)</strong> paradigmasiga asoslangan kuchli va moslashuvchan dasturlash tili. OOP yondashuvi dasturiy ta&rsquo;minot tuzilishini yanada tushunarli, qayta ishlatiladigan va modulli qilishga yordam beradi.</p>\n" +
            "<p>Agar siz <strong>Java OOP</strong> tushunchalarini <strong>batafsil o&lsquo;rganmoqchi bo&lsquo;lsangiz</strong>, <strong>Videohub.uz</strong> va <strong>Dasturlash.uz</strong> platformalari sizga yordam berishi mumkin.</p>\n" +
            "<p>📌 <strong>Videohub.uz</strong> da <strong>Java OOP bo&lsquo;yicha mukammal video darsliklar mavjud</strong>.</p>\n" +
            "<p>📌 <strong>Dasturlash.uz</strong> da <strong>Java OOP haqida o&lsquo;zbek tilidagi maqolalar va amaliy misollarni o&lsquo;qishingiz mumkin</strong>.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>OOP nima va u nima uchun kerak?</strong></h2>\n" +
            "<p><strong>Obyektga yo&lsquo;naltirilgan dasturlash (OOP)</strong> &ndash; bu <strong>dasturiy ta&rsquo;minotni obyektlar va ularning o&lsquo;zaro aloqasi asosida qurishga yordam beruvchi dasturlash yondashuvi</strong>. OOP quyidagi muhim tamoyillarga asoslanadi:</p>\n" +
            "<p>🔹 <strong>Inheritance (Meros olish)</strong> &ndash; Kodni qayta ishlatish va umumiy xususiyatlarni saqlash uchun ishlatiladi.<br>🔹 <strong>Encapsulation (Inkaptsulyatsiya)</strong> &ndash; Xavfsizlikni oshirish uchun ma&rsquo;lumotlarni yashirish imkonini beradi.<br>🔹 <strong>Polymorphism (Polimorfizm)</strong> &ndash; Turli xil obyektlarni bir xil interfeys bilan boshqarish imkonini yaratadi.<br>🔹 <strong>Abstraction (Abstraksiya)</strong> &ndash; Muhim xususiyatlarni ajratib ko&lsquo;rsatib, murakkablikni kamaytiradi.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Java OOP asosiy tamoyillari</strong></h2>\n" +
            "<h3><strong>1️⃣ Encapsulation (Inkaptsulyatsiya)</strong></h3>\n" +
            "<p>Encapsulation &ndash; bu <strong>obyekt ichidagi ma&rsquo;lumotlarni himoya qilish</strong> va <strong>ularni faqat kerakli metodlar orqali boshqarish</strong> tamoyilidir.</p>\n" +
            "<p>✅ <strong>private</strong> maydonlarni <strong>getter</strong> va <strong>setter</strong> metodlari orqali boshqarish kerak.</p>\n" +
            "<pre><code>public class Car {\n" +
            "    private String model;\n" +
            "    private int year;\n" +
            "\n" +
            "    public String getModel() {\n" +
            "        return model;\n" +
            "    }\n" +
            "\n" +
            "    public void setModel(String model) {\n" +
            "        this.model = model;\n" +
            "    }\n" +
            "\n" +
            "    public int getYear() {\n" +
            "        return year;\n" +
            "    }\n" +
            "\n" +
            "    public void setYear(int year) {\n" +
            "        this.year = year;\n" +
            "    }\n" +
            "}\n" +
            "</code></pre>\n" +
            "<p>📌 <strong>Videohub.uz</strong> da <strong>Encapsulation bo&lsquo;yicha batafsil darslar mavjud!</strong></p>\n" +
            "<hr>\n" +
            "<h3><strong>2️⃣ Inheritance (Meros olish)</strong></h3>\n" +
            "<p>Meros olish &ndash; bu <strong>bitta klassning boshqa klassdan xususiyat va metodlarni meros olishi</strong>.</p>\n" +
            "<p>✅ <strong>superclass</strong> va <strong>subclass</strong> munosabatini yaratish uchun <strong>extends</strong> kalit so&lsquo;zi ishlatiladi.</p>\n" +
            "<pre><code>class Animal {\n" +
            "    void makeSound() {\n" +
            "        System.out.println(\"Some sound...\");\n" +
            "    }\n" +
            "}\n" +
            "\n" +
            "class Dog extends Animal {\n" +
            "    void bark() {\n" +
            "        System.out.println(\"Woof woof!\");\n" +
            "    }\n" +
            "}\n" +
            "\n" +
            "public class Main {\n" +
            "    public static void main(String[] args) {\n" +
            "        Dog dog = new Dog();\n" +
            "        dog.makeSound(); // Superclass metodi\n" +
            "        dog.bark();      // O&lsquo;zining metodi\n" +
            "    }\n" +
            "}\n" +
            "</code></pre>\n" +
            "<p>📌 <strong>Videohub.uz</strong> va <strong>Dasturlash.uz</strong> da <strong>Inheritance bo&lsquo;yicha maqolalar va darslar mavjud!</strong></p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h3><strong>3️⃣ Polymorphism (Polimorfizm)</strong></h3>\n" +
            "<p>Polimorfizm &ndash; bu <strong>bir xil metodni turli sinflar tomonidan turlicha bajarish imkoniyati</strong>.</p>\n" +
            "<p>✅ <strong>Method Overriding</strong> &ndash; ota klass metodini o&lsquo;zgartirish.<br>✅ <strong>Method Overloading</strong> &ndash; bitta nom bilan turli xil parametrli metodlarni yaratish.</p>\n" +
            "<pre><code>class Animal {\n" +
            "    void makeSound() {\n" +
            "        System.out.println(\"Some sound...\");\n" +
            "    }\n" +
            "}\n" +
            "\n" +
            "class Cat extends Animal {\n" +
            "    @Override\n" +
            "    void makeSound() {\n" +
            "        System.out.println(\"Meow meow!\");\n" +
            "    }\n" +
            "}\n" +
            "\n" +
            "public class Main {\n" +
            "    public static void main(String[] args) {\n" +
            "        Animal myCat = new Cat();\n" +
            "        myCat.makeSound(); // \"Meow meow!\" chiqadi\n" +
            "    }\n" +
            "}\n" +
            "</code></pre>\n" +
            "<p>📌 <strong>Videohub.uz</strong> va <strong>Dasturlash.uz</strong> da <strong>Polimorfizm bo&lsquo;yicha mukammal maqolalar mavjud!</strong></p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h3><strong>4️⃣ Abstraction (Abstraksiya)</strong></h3>\n" +
            "<p>Abstraksiya &ndash; bu <strong>faqat kerakli xususiyatlarni ajratib, keraksiz tafsilotlarni yashirish</strong> tamoyili.</p>\n" +
            "<p>✅ <strong>abstract klass</strong> va <strong>interface</strong> lar orqali amalga oshiriladi.</p>\n" +
            "<pre><code>abstract class Vehicle {\n" +
            "    abstract void start();\n" +
            "}\n" +
            "\n" +
            "class Car extends Vehicle {\n" +
            "    @Override\n" +
            "    void start() {\n" +
            "        System.out.println(\"Car is starting...\");\n" +
            "    }\n" +
            "}\n" +
            "\n" +
            "public class Main {\n" +
            "    public static void main(String[] args) {\n" +
            "        Vehicle myCar = new Car();\n" +
            "        myCar.start();\n" +
            "    }\n" +
            "}\n" +
            "</code></pre>\n" +
            "<p>📌 <strong>Videohub.uz</strong> da <strong>Abstraction bo&lsquo;yicha batafsil darslar bor!</strong></p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Java OOP ni qayerdan o&lsquo;rganish mumkin?</strong></h2>\n" +
            "<p>Agar siz <strong>Java OOP ni boshlang&lsquo;ichdan professional darajaga qadar o&lsquo;rganmoqchi bo&lsquo;lsangiz</strong>, quyidagi manbalar sizga yordam beradi:</p>\n" +
            "<p>📌 <strong>Videohub.uz</strong> &ndash; <strong>Java OOP bo&lsquo;yicha to&lsquo;liq video kurslar mavjud.</strong><br>📌 <strong>Dasturlash.uz</strong> &ndash; <strong>Java OOP haqida maqolalar va amaliy misollar bor.</strong></p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Xulosa</strong></h2>\n" +
            "<p>✅ <strong>Java OOP</strong> &ndash; <strong>dasturiy ta&rsquo;minotni obyektlar asosida yaratishning samarali usuli</strong>.<br>✅ <strong>Encapsulation, Inheritance, Polymorphism va Abstraction</strong> &ndash; OOP ning asosiy tamoyillari.<br>✅ <strong>Videohub.uz</strong> da <strong>Java OOP bo&lsquo;yicha mukammal video darslar mavjud</strong>.<br>✅ <strong>Dasturlash.uz</strong> da <strong>Java OOP bo&lsquo;yicha maqolalar va amaliy misollar bor</strong>.</p>\n" +
            "<p>🚀 <strong>Java OOP ni o&lsquo;rganing va professional dasturchiga aylaning!</strong></p>\n" +
            "<p>&nbsp;</p>\n" +
            "<p>&nbsp;</p>"  },
    {
        id: "Spring-Security",
        title: "Spring Security",
        img: "https://api.videohub.uz/api/v1/attach/open-all/40940a9d-e460-43f7-b10d-56ebcd355757",
        description: "Spring Security — bu Java ilovalari uchun xavfsizlikni ta’minlovchi kuchli va moslashuvchan framework hisoblanadi. U autentifikatsiya (foydalanuvchini tekshirish) va avtorizatsiya (ruxsatlarni boshqarish) jarayonlarini soddalashtiradi hamda xavfsizlikni kuchaytirish uchun turli himoya mexanizmlarini taklif qiladi. Spring ekotizimining ajralmas qismi bo‘lgan bu vosita ayniqsa Spring Boot bilan birga ishlaganda juda qulay va samarali bo‘ladi.",
        content: "<h1>&nbsp;</h1>\n" +
            "<h1><strong>Spring Security: Java ilovalari uchun xavfsizlik tizimi</strong></h1>\n" +
            "<p>&nbsp;</p>\n" +
            "<h2><strong>Kirish</strong></h2>\n" +
            "<p><strong>Spring Security</strong> &ndash; bu <strong>Java ilovalarining xavfsizligini ta&rsquo;minlash uchun mo&lsquo;ljallangan kuchli va moslashuvchan framework</strong>. U <strong>avtorizatsiya va autentifikatsiya jarayonlarini boshqarish</strong>, <strong>foydalanuvchilar huquqlarini nazorat qilish</strong>, hamda <strong>xavfsizlik tahdidlaridan himoya qilish</strong> uchun keng qo&lsquo;llaniladi.</p>\n" +
            "<p>Agar siz <strong>Spring Security ni mukammal o&lsquo;rganmoqchi bo&lsquo;lsangiz</strong>, <strong>Videohub.uz</strong> va <strong>Dasturlash.uz</strong> platformalari sizga yordam bera oladi:</p>\n" +
            "<p>📌 <strong>Videohub.uz</strong> da <strong>Spring Security bo&lsquo;yicha batafsil video darsliklar mavjud</strong>.</p>\n" +
            "<p>📌 <strong>Dasturlash.uz</strong> da <strong>Spring Security haqida o&lsquo;zbek tilidagi maqolalar va amaliy misollarni o&lsquo;qishingiz mumkin</strong>.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Spring Security nima va u nima uchun kerak?</strong></h2>\n" +
            "<p>Spring Security &ndash; <strong>Spring ekotizimining eng muhim qismlaridan biri bo&lsquo;lib, ilovalarning xavfsizligini ta&rsquo;minlaydi</strong>.</p>\n" +
            "<p>✅ <strong>Autentifikatsiya</strong> &ndash; Foydalanuvchini <strong>login</strong> va <strong>parol</strong> orqali tekshirish.<br>✅ <strong>Avtorizatsiya</strong> &ndash; Foydalanuvchiga <strong>ruxsat etilgan yoki etilmagan imkoniyatlarni boshqarish</strong>.<br>✅ <strong>Sessiya boshqaruvi</strong> &ndash; Foydalanuvchilar sessiyasini nazorat qilish.<br>✅ <strong>Xavfsizlik tahdidlariga qarshi himoya</strong> &ndash; <strong>CSRF</strong>, <strong>XSS</strong>, <strong>Brute Force</strong>, <strong>Session Hijacking</strong> kabi hujumlardan himoya qilish.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Spring Security qanday ishlaydi?</strong></h2>\n" +
            "<p>Spring Security <strong>filterlar zanjiri</strong> orqali ilova xavfsizligini ta&rsquo;minlaydi. U quyidagi asosiy jarayonlarni amalga oshiradi:</p>\n" +
            "<p>1️⃣ <strong>Foydalanuvchi kirish ma&rsquo;lumotlarini yuboradi (login, parol)</strong>.<br>2️⃣ <strong>Spring Security ushbu ma&rsquo;lumotlarni tekshiradi va tasdiqlaydi</strong>.<br>3️⃣ <strong>Agar ma&rsquo;lumotlar to&lsquo;g&lsquo;ri bo&lsquo;lsa, foydalanuvchiga sessiya ochiladi</strong>.<br>4️⃣ <strong>Har bir so&lsquo;rovda foydalanuvchi roli tekshiriladi va faqat ruxsat berilgan joylarga kirishiga yo&lsquo;l qo&lsquo;yiladi</strong>.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Spring Security ni qanday sozlash mumkin?</strong></h2>\n" +
            "<p>Spring Security ni <strong>Spring Boot</strong> bilan oson integratsiya qilish mumkin. Quyida <strong>minimal Spring Security konfiguratsiyasi</strong> keltirilgan:</p>\n" +
            "<h3><strong>1️⃣ Spring Security kutubxonalarini qo&lsquo;shish</strong></h3>\n" +
            "<p>Agar siz <strong>Maven</strong> ishlatayotgan bo&lsquo;lsangiz, <code>pom.xml</code> fayliga quyidagi kodni qo&lsquo;shing:</p>\n" +
            "<pre><code>&lt;dependency&gt;\n" +
            "    &lt;groupId&gt;org.springframework.boot&lt;/groupId&gt;\n" +
            "    &lt;artifactId&gt;spring-boot-starter-security&lt;/artifactId&gt;\n" +
            "&lt;/dependency&gt;\n" +
            "&lt;dependency&gt;\n" +
            "    &lt;groupId&gt;org.springframework.boot&lt;/groupId&gt;\n" +
            "    &lt;artifactId&gt;spring-boot-starter-web&lt;/artifactId&gt;\n" +
            "&lt;/dependency&gt;\n" +
            "</code></pre>\n" +
            "<p>📌 <strong>Videohub.uz</strong> da <strong>Spring Security kutubxonalarini to&lsquo;g&lsquo;ri o&lsquo;rnatish bo&lsquo;yicha batafsil darslar mavjud!</strong></p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h3><strong>2️⃣ Foydalanuvchi autentifikatsiyasini sozlash</strong></h3>\n" +
            "<p>Spring Security da foydalanuvchini autentifikatsiya qilish uchun <strong>SecurityConfig</strong> klassi yaratiladi:</p>\n" +
            "<pre><code>import org.springframework.context.annotation.Bean;\n" +
            "import org.springframework.context.annotation.Configuration;\n" +
            "import org.springframework.security.config.annotation.web.builders.HttpSecurity;\n" +
            "import org.springframework.security.config.annotation.web.configuration.EnableWebSecurity;\n" +
            "import org.springframework.security.crypto.bcrypt.BCryptPasswordEncoder;\n" +
            "import org.springframework.security.crypto.password.PasswordEncoder;\n" +
            "\n" +
            "@Configuration\n" +
            "@EnableWebSecurity\n" +
            "public class SecurityConfig {\n" +
            "\n" +
            "    @Bean\n" +
            "    public PasswordEncoder passwordEncoder() {\n" +
            "        return new BCryptPasswordEncoder();\n" +
            "    }\n" +
            "\n" +
            "    @Bean\n" +
            "    public SecurityFilterChain securityFilterChain(HttpSecurity http) throws Exception {\n" +
            "        http\n" +
            "            .authorizeHttpRequests(auth -&gt; auth\n" +
            "                .requestMatchers(\"/admin/**\").hasRole(\"ADMIN\")\n" +
            "                .requestMatchers(\"/user/**\").hasRole(\"USER\")\n" +
            "                .anyRequest().authenticated()\n" +
            "            )\n" +
            "            .formLogin()\n" +
            "            .and()\n" +
            "            .logout();\n" +
            "        return http.build();\n" +
            "    }\n" +
            "}\n" +
            "</code></pre>\n" +
            "<p>✅ Bu kod <strong>faqatgina adminlar va oddiy foydalanuvchilar uchun maxsus ruxsatlarni belgilaydi</strong>.</p>\n" +
            "<p>📌 <strong>Videohub.uz</strong> da <strong>Spring Security konfiguratsiyasini yaratish bo&lsquo;yicha ajoyib darsliklar bor!</strong></p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Spring Security asosiy modullari</strong></h2>\n" +
            "<p>Spring Security <strong>bir nechta muhim modullarga ega</strong>:</p>\n" +
            "<h3><strong>1️⃣ Spring Security Core</strong></h3>\n" +
            "<p>Spring Security ning asosiy moduli bo&lsquo;lib, foydalanuvchi autentifikatsiyasi va avtorizatsiyasini boshqaradi.</p>\n" +
            "<p>📌 <strong>Videohub.uz</strong> da <strong>Spring Security Core haqida batafsil darslar mavjud</strong>.</p>\n" +
            "<h3><strong>2️⃣ Spring Security JWT</strong></h3>\n" +
            "<p>JWT (JSON Web Token) &ndash; foydalanuvchi identifikatsiyasini <strong>tokenlar orqali amalga oshiruvchi</strong> texnologiya.</p>\n" +
            "<p>📌 <strong>Dasturlash.uz</strong> da <strong>Spring Security JWT bo&lsquo;yicha maqolalar mavjud</strong>.</p>\n" +
            "<h3><strong>3️⃣ Spring Security OAuth2</strong></h3>\n" +
            "<p>OAuth2 orqali <strong>foydalanuvchilarni Google, Facebook yoki GitHub kabi xizmatlar orqali autentifikatsiya qilish</strong> mumkin.</p>\n" +
            "<p>📌 <strong>Videohub.uz</strong> da <strong>Spring Security OAuth2 bo&lsquo;yicha batafsil darslar bor</strong>.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Spring Security ni qayerdan o&lsquo;rganish mumkin?</strong></h2>\n" +
            "<p>Agar siz <strong>Spring Security ni boshidan professional darajagacha o&lsquo;rganmoqchi bo&lsquo;lsangiz</strong>, quyidagi platformalar sizga yordam beradi:</p>\n" +
            "<p>📌 <strong>Videohub.uz</strong> &ndash; <strong>Spring Security bo&lsquo;yicha mukammal video darsliklar mavjud</strong>.<br>📌 <strong>Dasturlash.uz</strong> &ndash; <strong>Spring Security bo&lsquo;yicha o&lsquo;zbek tilida maqolalar va amaliy misollar bor</strong>.</p>\n" +
            "<p>&nbsp;</p>\n" +
            "<hr>\n" +
            "<h2><strong>Xulosa</strong></h2>\n" +
            "<p>✅ <strong>Spring Security</strong> &ndash; bu <strong>Java ilovalari uchun xavfsizlikni ta&rsquo;minlovchi kuchli framework</strong>.<br>✅ <strong>Autentifikatsiya, avtorizatsiya va tahdidlardan himoya qilish</strong> &ndash; Spring Security ning asosiy vazifalari.<br>✅ <strong>Spring Boot bilan Spring Security ni integratsiya qilish juda oson</strong>.<br>✅ <strong>Videohub.uz</strong> da <strong>Spring Security bo&lsquo;yicha mukammal video darslar mavjud</strong>.<br>✅ <strong>Dasturlash.uz</strong> da <strong>Spring Security haqida o&lsquo;zbek tilida maqolalar bor</strong>.</p>\n" +
            "<p>🚀 <strong>Spring Security ni o&lsquo;rganing va xavfsiz web ilovalar yaratishda ustaga aylaning!</strong></p>\n" +
            "<p>&nbsp;</p>\n" +
            "<p>&nbsp;</p>" },
]
const FaqList = () => {


    const handleClick = (id) => {
        window.location.href = "/faq-detail/" + id;
    }
    return (<div className='about-us'>
        <Header/>
        <Helmet >
            <meta property="og:title" content={"Videohub - Online Dasturlash Kurslari"} />
            <meta property="og:description" content={"Java, OOP, Spring, Hibernate va boshqa IT yo‘nalishlari bo‘yicha bepul va pullik video darslar. Dasturlashni o‘rganing va IT mutaxassisiga aylaning!"} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:img" content={"https://videohub.uz/logo.ico"} />
            <meta property="og:image:width" content="693"/>
            <meta property="og:image:height" content="437"/>
        </Helmet>
        <div className="main container-xxl">
            <h5 className='h3 pt-4'>FAQ</h5>
            <div className="faq-cards row">
                {articleList?.map((item, idx) => {

                    return <div className="col-12 col-md-6 col-xl-4 p-3" key={item.id}>
                        <div className="card shadow-lg" style={{cursor: "pointer"}}
                             onClick={() => {
                                 handleClick(item.id);
                             }}>
                            <img src={item.img} className="card-img-top fixed-img"
                                 alt="Videohub.uz Dasturlashni online o'rganing"/>
                            <div className="card-body">
                                <h5 className="card-faq-title">{item.title}</h5>
                                <p className="card-text"> {item.description}</p>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
        <Footer/>
    </div>);
}

export default FaqList;
