import React from "react";
import { Header } from "../../components";
import ClientLoginComponent from "../../components/client-login-component/clientLoginComponent";
import "./ClientLogInPage.scss";
import {Helmet} from "react-helmet-async";
function ClientLogInPage() {
  return (
    <>
      <Header />
        <Helmet >
            <meta property="og:title" content={"Videohub - Online Dasturlash Kurslari"} />
            <meta property="og:description" content={"Java, OOP, Spring, Hibernate va boshqa IT yo‘nalishlari bo‘yicha bepul va pullik video darslar. Dasturlashni o‘rganing va IT mutaxassisiga aylaning!"} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:img" content={"https://videohub.uz/logo.ico"} />
            <meta property="og:image:width" content="693"/>
            <meta property="og:image:height" content="437"/>
        </Helmet>
      <div className="loginPage">
        <section className="login-section">
          <ClientLoginComponent />
        </section>
      </div>
    </>
  );
}

export default ClientLogInPage;
