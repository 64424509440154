import React from 'react';
import {Footer, Header} from '../../components';
import './faq.scss';

import {articleList} from "./faq";
import {useParams} from "react-router-dom";

const FaqDetail = () => {
    const {id} = useParams();
    const item = articleList.find(item=> item.id === id);
    return (<div className=''>
        <Header/>
        <div className="faq-item-container">
            <div className="faq-item-card row">
               <h1 className="faq-item-title">{item.title}</h1>
                <img src={item.img} className="faq-item-img"
                     alt="Videohub.uz Dasturlashni online o'rganing"/>
                <br/>
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
            </div>
        </div>
        <Footer/>
    </div>);
}

export default FaqDetail;
