import { Modal } from "antd";
import { useEffect, useState } from "react";

function ChatSendAttachModal({ isModalOpen, setIsModalOpen, file, sendFile }) {
  const [canSendFile, setCanSendFile] = useState(false);
  let m = false;
  const handleOk = () => {
    setIsModalOpen(false);
    sendFile();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const displayFile = () => {
    const fileType = file?.data.type;
    if (fileType === "video/mp4" || fileType === "video/webm") {
      return (
        <video src={file.url} controls className="sending-modal__video"></video>
      );
    }
    if (fileType === "audio/mpeg") {
      return <audio src={file.url} controls></audio>;
    }
    if (
      fileType === "image/png" ||
      fileType === "image/jpg" ||
      fileType === "image/jpeg"
    ) {
      return <img src={file.url} alt="" className="sending-modal__img" />;
    }
    if (
      fileType === "application/pdf" ||
      fileType === "text/plain" ||
      fileType === "application/x-zip-compressed" ||
      fileType === "application/vnd.rar" // Добавлена поддержка RAR
    ) {
      return (
        <>
          <p>Filename: {file?.data.name}</p>
          <p>Filetype: {file?.data.type}</p>
          <p>Size in bytes: {file?.data.size}</p>
        </>
      );
    }
    m = true;
    return <p>Right now we do not support such file type</p>;
  };
  useEffect(() => {
    setCanSendFile(m);
  }, []);
  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      okText="Send"
      cancelText="Cancel"
      okButtonProps={{
        icon: <i className="fa-solid fa-paper-plane modal-icon"></i>,
        disabled: canSendFile,
      }}
      onCancel={handleCancel}
      className="sending-modal"
    >
      {displayFile()}
    </Modal>
  );
}

export default ChatSendAttachModal;
