export const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

export const secondsToTime = (duration) => {
  // Hours, minutes and seconds
  if (!duration || duration === 0) {
    return "";
  }
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;
  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = "";
  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }
  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
};

export const secondsToTimeWithDef = (duration) => {
  // Hours, minutes and seconds
  if (!duration || duration === 0) {
    return "";
  }
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;
  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = "";
  if (hrs > 0) {
    ret += "" + hrs + "h - " + (mins < 10 ? "0" : "");
  }
  ret += "" + mins + "min - " + (secs < 10 ? "0" : "");
  ret += "" + secs +"sec";
  return ret;
};

export const handleUnAuthorized = () => {
  // TODO
  // if (error.response.status === 401) {
  localStorage.removeItem("loginPhoneNumber");
  localStorage.removeItem("fullName");
  localStorage.removeItem("adminUser");
  localStorage.removeItem("token");
  localStorage.removeItem("registerUserData");
  window.location.reload();
  // }
};

export const isTokenExists = () => {
  const toke = localStorage.getItem("token");
  return toke ? true : false;
};

export const getDateInSimpleFormat = (date) => {
  if (date !== null && date !== undefined) {
    return date.replace("T", " ").substring(0, 16);
  }
};

export const getDateFormat = (date) => {
  if (date !== null && date !== undefined) {
    return date.replace("T", " ").substring(0, 19);
  }
};

export const currencyFormat = (num) => {
  if (num === null || num === undefined) {
    return "";
  }
  return "" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const splitAndReturnAsArray = (str) => {
  if (str === null || str === undefined) {
    return [];
  }
  if (!str.includes(",")) {
    return [str];
  }
  return str.split(",");
};
