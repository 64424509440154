import {useEffect} from "react";
import {secondsToTime, secondsToTimeWithDef} from "../../utils/Utils";

const JsonLdSchema = ({course}) => {
    useEffect(() => {
        const script = document.createElement("script");
        script.type = "application/ld+json";
        script.innerHTML = JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Course",
            "name": course?.name,
            "description": course?.shortDescription,
            "educationalLevel": course?.level,
            "duration": secondsToTimeWithDef(course?.videoDuration),
            "offers": {
                "@type": "Offer",
                "price": course?.price,
                "priceCurrency": "UZS"
            },
            "provider": {
                "@type": "EducationalOrganization",
                "name": "Videohub",
                "url": "https://videohub.uz"
            },
            "instructor": {
                "@type": "Person",
                "name": getInstructor(course?.instructor)
            }
        });

        document.head.appendChild(script);
        return () => {
            document.head.removeChild(script);
        };
    }, [course]);

    const getInstructor = (mentorList) => {
        if (!mentorList || mentorList.length === 0) {
            return "";
        }
        return mentorList[0]?.name + " " + mentorList[0]?.surname;
    }

    return null; // UI da hech narsa ko'rinmaydi, faqat metadata qo'shadi
};

export default JsonLdSchema;
