import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Tabs} from 'antd';
import {BASE_URL} from '../../api/base-url';
import {Header, Footer, Filter} from '../../components';
import './my-course.scss';
import {FinishedCourses, MyCourses} from '../my-courses/tabs';
import axiosInstance from "../../api/intercepter";
import {Helmet} from "react-helmet-async";

const MyCourse = () => {
    const onChange = (key) => {
    };

    const [checkState, setCheckState] = useState({ids: []});
    const [showFilter, setShowFilter] = useState(true);
    const [data, setData] = useState([]);
    const [value, setValue] = useState({
        searchInputValue: '',
        checkboxTopicValue: [],
    });

    const handleFilter = () => {
        setShowFilter(!showFilter);
    };
    const getCourses = async () => {
        const {data} = await axiosInstance.post(
            `${BASE_URL}/api/v1/course/public/filter`,
            {
                name: value.searchInputValue,
            }
        );
        setData(data.data.content);
    };

    useEffect(() => {
        getCourses();
    }, [value]);

    const items = [
        {
            key: '1',
            label: `Mening Kurslarim`,
            children: <MyCourses/>,
        },
        {
            key: '2',
            label: `Tugatilgan`,
            children: <FinishedCourses/>,
        },
    ];
    return (
        <div className='catalog-wrapper'>
            <Header/>
            <Helmet >
                <meta property="og:title" content={"Videohub - Online Dasturlash Kurslari"} />
                <meta property="og:description" content={"Java, OOP, Spring, Hibernate va boshqa IT yo‘nalishlari bo‘yicha bepul va pullik video darslar. Dasturlashni o‘rganing va IT mutaxassisiga aylaning!"} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:img" content={"https://videohub.uz/logo.ico"} />
                <meta property="og:image:width" content="693"/>
                <meta property="og:image:height" content="437"/>
            </Helmet>
            <div className='input-wrap'></div>
            <div className='catalog-items-wrapper'>
                <div className='filter'>
                    {showFilter && <Filter setCheckState={setCheckState}/>}
                </div>
                <Tabs
                    className='tabs'
                    defaultActiveKey='1'
                    type='card'
                    items={items}
                    onChange={onChange}
                />
            </div>
            <Footer/>
        </div>
    );
};

export default MyCourse;
