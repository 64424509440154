import React, { useState, useEffect } from "react";
import { Timeline, ConfigProvider } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Footer, Header } from "../../components";
import "./timeline.scss";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet-async";

const AppTimeline = () => {
  const [mode, setMode] = useState(
    window.innerWidth > 768 ? "alternate" : "left"
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setMode("left");
      } else {
        setMode("alternate");
      }
    };

    // Add event listener on component mount
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="timeline">
      <Header />
      <Helmet >
        <meta property="og:title" content={"Videohub - Online Dasturlash Kurslari"} />
        <meta property="og:description" content={"Java, OOP, Spring, Hibernate va boshqa IT yo‘nalishlari bo‘yicha bepul va pullik video darslar. Dasturlashni o‘rganing va IT mutaxassisiga aylaning!"} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:img" content={"https://videohub.uz/logo.ico"} />
        <meta property="og:image:width" content="693"/>
        <meta property="og:image:height" content="437"/>
      </Helmet>
      <div className="main container-xxl">
        <h5 className="h3 pt-4 pt-xl-0">Timeline</h5>
        {/* <AntdTimeline mode="alternate" theme="light" /> */}

        <div className="timeline-wrapper">
          <Timeline mode={mode} theme="light">
            <Timeline.Item>
              <div className="timeline-item timeline-item__right">
                <h4>Title</h4>
                <p className="timeline-item_period mb-2">3 oy - 5 oy</p>
                <h5 className="mb-3 text-dark">Description</h5>
                <p>
                  In the case of the Timeline component, you can modify its
                  appearance by defining or overriding design tokens. Here's how
                  you can apply them
                </p>
                <ul className="timeline-list">
                  <li className="">
                    <Link> Spring Data</Link>
                  </li>
                  <li>
                    <Link>Spring Boot + Spring Rest</Link>
                  </li>
                  <li>
                    <Link>G'iybat loyihasi</Link>
                  </li>
                </ul>
              </div>
            </Timeline.Item>
            <Timeline.Item>
              <div className="timeline-item timeline-item__left">
                <h4>Title</h4>
                <p className="timeline-item_period mb-2">3 oy - 5 oy</p>
                <h5 className="mb-3 text-dark">Description</h5>
                <p>
                  In the case of the Timeline component, you can modify its
                  appearance by defining or overriding design tokens. Here's how
                  you can apply them
                </p>
                <ul className="timeline-list">
                  <li className="">
                    <Link> Spring Data</Link>
                  </li>
                  <li>
                    <Link>Spring Boot + Spring Rest</Link>
                  </li>
                  <li>
                    <Link>G'iybat loyihasi</Link>
                  </li>
                </ul>
              </div>
            </Timeline.Item>
            <Timeline.Item>
              <div className="timeline-item timeline-item__right">
                <h4>Title</h4>
                <p className="timeline-item_period mb-2">3 oy - 5 oy</p>
                <h5 className="mb-3 text-dark">Description</h5>
                <p>
                  In the case of the Timeline component, you can modify its
                  appearance by defining or overriding design tokens. Here's how
                  you can apply them
                </p>
                <ul className="timeline-list">
                  <li className="">
                    <Link> Spring Data</Link>
                  </li>
                  <li>
                    <Link>Spring Boot + Spring Rest</Link>
                  </li>
                  <li>
                    <Link>G'iybat loyihasi</Link>
                  </li>
                </ul>
              </div>
            </Timeline.Item>
            <Timeline.Item>
              <div className="timeline-item timeline-item__left">
                <h4>Title</h4>
                <p className="timeline-item_period mb-2">3 oy - 5 oy</p>
                <h5 className="mb-3 text-dark">Description</h5>
                <p>
                  In the case of the Timeline component, you can modify its
                  appearance by defining or overriding design tokens. Here's how
                  you can apply them
                </p>
                <ul className="timeline-list">
                  <li className="">
                    <Link> Spring Data</Link>
                  </li>
                  <li>
                    <Link>Spring Boot + Spring Rest</Link>
                  </li>
                  <li>
                    <Link>G'iybat loyihasi</Link>
                  </li>
                </ul>
              </div>
            </Timeline.Item>
          </Timeline>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AppTimeline;
// items={[
//     {
//       children: "Create a services site 2015-09-01",
//     },
//     {
//       children: "Solve initial network problems 2015-09-01",
//       color: "green",
//     },
//     {
//       dot: (
//         <ClockCircleOutlined
//           style={{
//             fontSize: "16px",
//           }}
//         />
//       ),
//       children: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.`,
//     },
//     {
//       color: "red",
//       children: "Network problems being solved 2015-09-01",
//     },
//     {
//       children: "Create a services site 2015-09-01",
//     },
//     {
//       dot: (
//         <ClockCircleOutlined
//           style={{
//             fontSize: "16px",
//           }}
//         />
//       ),
//       children: "Technical testing 2015-09-01",
//     },
//   ]}
