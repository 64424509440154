import React, { useContext, useEffect, useState } from "react";
import { Popover } from "antd";
import { Link, NavLink, useLocation } from "react-router-dom";
import Icon from "../../assets/icon.png";
import AuthContext from "../../context/auth-provider";
import "./headerComponent.scss";
import { useNavigate } from "react-router-dom";

function HeaderComponent() {
  // const { register, login, admin } = useContext(AuthContext);
  const { userData } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const userName = JSON.parse(localStorage.getItem("fullName"));
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [openPopover, setOpenPopover] = useState(false);
  const hidePopover = () => {
    setOpenPopover(false);
  };
  const handleOpenPopoverChange = (newOpen) => {
    setOpenPopover(newOpen);
  };

  const logOut = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  const content = (
    <div>
      <Link
        onClick={hidePopover}
        state={{ userData }}
        to={
          userData?.roleList.includes("ROLE_MENTOR")
            ? "/mentor/profile"
            : "/profile"
        }
      >
        <p style={{ cursor: "pointer", color: "#000" }}>Dashboard</p>
      </Link>

      <p onClick={logOut} style={{ cursor: "pointer", color: "#07a2ce" }}>
        Log Out
      </p>
    </div>
  );

  return (
    <div className="navbar-wrapper">
      <div className="navbar-left d-flex align-items-center gap-2">
        <div className="logo" onClick={() => setIsMobileMenuOpen(false)}>
          <Link
            style={{ display: "flex", alignItems: "center", gap: 15 }}
            to="/"
          >
            <img src={Icon} alt="" />
            <h4 className="text-light mb-0">Videohub</h4>
          </Link>
        </div>
        <ul
          className={`navbar-links d-flex gap-2 ${
            isMobileMenuOpen ? "navbar-links__mobile" : ""
          }`}
        >
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? "active-link" : "link")}
          >
            Home
          </NavLink>
          <NavLink
            to="/catalog"
            className={({ isActive }) => (isActive ? "active-link" : "link")}
          >
            Catalog
          </NavLink>
          <NavLink
            to={userData?.jwt ? "/my-courses" : "/login"}
            className={pathname === "/my-courses" ? "active-link" : "link"}
          >
            My Learning
          </NavLink>
          <NavLink
            to={"/about-us"}
            className={pathname === "/about-us" ? "active-link" : "link"}
          >
            About Us
          </NavLink>
          <NavLink
              to={"/faq"}
              className={pathname === "/faq" ? "active-link" : "link"}>
            FAQ
          </NavLink>
          {isMobileMenuOpen && (
            <div
              className={`navbar-right  navbar-right_mobile pt-5 ${
                isMobileMenuOpen ? "navbar-right_mobile" : ""
              }`}
            >
              <Popover
                className="d-flex gap-5 justify-content-center align-items-center"
                placement="bottom"
                content={content}
                trigger="click"
                open={openPopover}
                onOpenChange={handleOpenPopoverChange}
              >
                {userName ? (
                  <div className="user">
                    <i className="fa-solid fa-caret-down text-light"></i>
                    <span className="avatar-name navbar-text">
                      {userName?.name} {userName?.surname}
                    </span>
                    <div className="avatar-img">
                      <img
                        src={`${
                          userName?.photo?.url ||
                          "https://www.w3schools.com/howto/img_avatar.png"
                        }`}
                        alt=""
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <Link to="/login" className="navbar__link">
                      Sign In
                    </Link>
                    <Link to="/register" className="navbar__link">
                      Sign Up
                    </Link>
                  </>
                )}
              </Popover>
            </div>
          )}
        </ul>
      </div>
      <div className="navbar-right">
        <Popover
          className="d-flex gap-4 justify-content-center align-items-center"
          placement="bottom"
          content={content}
          trigger="click"
          open={openPopover}
          onOpenChange={handleOpenPopoverChange}
        >
          {userData ? (
            <div className="user">
              <i className="fa-solid fa-caret-down text-light"></i>
              <span className="avatar-name navbar-text">
                {userData?.name} {userData?.surname}
              </span>
              <div className="avatar-img">
                <img
                  src={`${
                    userData?.photo?.url ||
                    "https://www.w3schools.com/howto/img_avatar.png"
                  }`}
                  alt=""
                />
              </div>
            </div>
          ) : (
            <>
              <Link to="/login" className="navbar__link">
                Sign In
              </Link>
              <Link to="/register" className="navbar__link">
                Sign Up
              </Link>
            </>
          )}
        </Popover>
      </div>
      <div
        className="burger-menu p-2"
        onClick={() => setIsMobileMenuOpen((prev) => !prev)}
      >
        <i className="bi bi-list fs-1"></i>
      </div>
    </div>
  );
}

export default HeaderComponent;
