import React from "react";
import { Link } from "react-router-dom";
import "./catalogItem.scss";

const CatalogItem = ({ itemCourse, isDisplayPrice = true }) => {
  const {
    banner,
    name,
    rating,
    price,
    shortDescription,
    id,
    course,
    mentorList,
    topicList,
  } = itemCourse;

  return (
    <Link
        to={`/course-detail/${name.toLowerCase()
            .replaceAll("+ ","")
            .replaceAll("-","")
            .replaceAll(" ", "-")}/${id}`}
          state={{ id }}>
      <div className="catalog-item-wrapper">
        <div
          style={{
            backgroundImage: `url(${
              banner?.url ||
              course?.banner?.url ||
              "https://t3.ftcdn.net/jpg/02/93/97/96/240_F_293979646_NUleDAp6GqyhT6dztQtuprvUDxuN2gFG.jpg"
            })`,
          }}
          className="catalog-head d-flex justify-content-between align-items-start"
        >
          <div className="head-left d-flex gap-3">
            <span
              style={{ cursor: "pointer" }}
              className="catalog-type text-light"
            >
              <i className="bi bi-globe2 head-icon me-2"></i>
              eLearning
            </span>
            <span
              style={{ cursor: "pointer" }}
              className="catalog-type text-light"
            >
              <i className="bi bi-translate head-icon me-2"></i>
              BUL
            </span>
          </div>
          <div className="head-right text-light">
            <span style={{ cursor: "pointer" }}>
              <i className="fa-regular head-icon fa-bookmark"></i>
            </span>
          </div>
        </div>
        <div className="catalog-item-description p-3 text-light">
          <div className="description-head d-flex justify-content-between align-items-center">
            <h6 className="mt-2">{name || course?.name}</h6>
            <span className="d-flex align-items-center gap-2">
              <i style={{ color: "#fadb14" }} className="fa-solid fa-star"></i>
              <span>{rating || course?.rating}</span>
            </span>
          </div>
          <div className="description-tags">
            {topicList?.map((item, index) => (
              <span key={item.name + " " + index}>
                <span>#{item.name}</span>
              </span>
            ))}
          </div>
          <div className="description-paragraph">
            <p>{shortDescription}</p>
          </div>
        </div>
        <div>
          {mentorList?.map((item, index) => (
            <div key={item.id} className="catalog-item-mentor-about">
              <img
                style={{ height: 80, width: 80, borderRadius: "50%" }}
                src={
                  item?.attachUrl
                    ? item.attachUrl
                    : "https://cdn.pixabay.com/photo/2013/07/13/12/07/avatar-159236__340.png"
                }
                alt=""
              />
              <div className="mentor-name">
                <p>
                  {item.surname} {item.name}
                </p>
                <p>{item.shortInfo}</p>
              </div>
            </div>
          ))}
        </div>
        {isDisplayPrice && (
          <div className="course-price p-2">
            <>
              <p>{price === 0 ? "Bepul" : `${price} UZS`}</p>
              <i className="fa-solid fa-user"></i>
            </>
          </div>
        )}
      </div>
    </Link>
  );
};

export default CatalogItem;
